import React, { useState, useEffect } from 'react';
import { AlertOctagon, CheckCircle2, Settings, ArrowRight } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useNavigate } from 'react-router-dom';

interface Home {
  id: string;
  address: string;
}

interface HomeSystem {
  home_id: string;
  heating_systems: string[];
  cooling_systems: string[];
  appliances: string[];
  other_systems: string[];
  upgrades: string[];
  outdoor_features: string[];
}

const ImportantReminders: React.FC = () => {
  const navigate = useNavigate();
  const [unconfiguredHomes, setUnconfiguredHomes] = useState<Home[]>([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    checkHomeSystems();
  }, []);

  const checkHomeSystems = async () => {
    try {
      // Get the current user's ID
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('No authenticated user');
      }

      // Get homes owned by the current user
      const { data: homes, error: homesError } = await supabase
        .from('homes')
        .select('id, address')
        .eq('owner_id', user.id);

      if (homesError) throw homesError;

      if (!homes || homes.length === 0) {
        setLoading(false);
        return;
      }

      // Get home systems configurations for the user's homes
      const { data: systems, error: systemsError } = await supabase
        .from('home_systems')
        .select('home_id, heating_systems, cooling_systems, appliances, other_systems, upgrades, outdoor_features')
        .in('home_id', homes.map(h => h.id))
        .eq('home_id', homes.map(h => h.id));

      if (systemsError) throw systemsError;

      // Find homes without systems configured
      const unconfigured = homes.filter(home => {
        const homeSystem = systems?.find(s => s.home_id === home.id);
        if (!homeSystem) return true;

        // Check if any system array is empty or null
        const hasConfiguration = Object.values(homeSystem).some(value => 
          Array.isArray(value) && value.length > 0
        );
        return !hasConfiguration;
      });

      setUnconfiguredHomes(unconfigured);
    } catch (error) {
      console.error('Error checking home systems:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) return null;

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-6">Important Reminders</h2>
        <div className="space-y-4">
          {unconfiguredHomes.length > 0 && (
            <div className="flex items-start space-x-3">
              <div className="w-8 h-8 bg-blue-50 rounded-lg flex items-center justify-center mt-1">
                <Settings className="w-4 h-4 text-blue-600" />
              </div>
              <div className="flex-1">
                <div className="flex items-start justify-between">
                  <div>
                    <p className="font-medium">Configure Home Systems</p>
                    <p className="text-sm text-gray-600">
                      {unconfiguredHomes.length === 1 
                        ? `Set up systems for ${unconfiguredHomes[0].address}`
                        : `${unconfiguredHomes.length} homes need system configuration`
                      }
                    </p>
                  </div>
                  <button
                    onClick={() => navigate('/maintenance-plan')}
                    className="flex items-center space-x-1 text-sm text-blue-600 hover:text-blue-800"
                  >
                    <span>Configure</span>
                    <ArrowRight className="w-4 h-4" />
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ImportantReminders;