import React, { useState, useEffect, useRef } from 'react';
import { Bot, Send, Loader2, User } from 'lucide-react';
import { supabase } from '../lib/supabase';
import ChatMessage from '../components/chat/ChatMessage';

interface Message {
  id?: string;
  role: 'user' | 'assistant';
  content: string;
  created_at?: string;
  thread_id?: string;
  assistant_message_id?: string;
  run_id?: string;
  actions?: Array<{
    type: 'viewInventory' | 'viewInventoryItem' | 'viewMaintenancePlan' | 'createServiceRequest';
    data?: {
      itemId?: string;
      itemName?: string;
    };
  }>;
}

interface Profile {
  full_name: string;
  phone_number: string;
  plan: string;
  created_at: string;
}

interface Home {
  id: string;
  address: string;
  city: string;
  state: string;
}

interface InventoryItem {
  id: string;
  name: string;
  category: string;
  model_number: string;
  purchase_date: string;
  purchase_price: number;
}

const Assistant = () => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [input, setInput] = useState('');
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true);
  const [userProfile, setUserProfile] = useState<Profile | null>(null);
  const [userHomes, setUserHomes] = useState<Home[]>([]);
  const [inventoryItems, setInventoryItems] = useState<InventoryItem[]>([]);
  const [avatarError, setAvatarError] = useState(false);
  const [currentThreadId, setCurrentThreadId] = useState<string | null>(null);
  const messagesEndRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    Promise.all([
      fetchUserData(),
      fetchOrCreateThread()
    ]).finally(() => {
      setInitialLoading(false);
    });
  }, []);

  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const fetchOrCreateThread = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) return;

      // Get the user's most recent thread
      const { data: threads, error: threadsError } = await supabase
        .from('threads')
        .select('*')
        .eq('user_id', user.id)
        .order('created_at', { ascending: false })
        .limit(1);

      if (threadsError) throw threadsError;

      let threadId = threads?.[0]?.assistant_thread_id;

      if (threadId) {
        setCurrentThreadId(threadId);
        // Fetch messages for this thread
        const { data: messages, error: messagesError } = await supabase
          .from('assistant_messages')
          .select('*')
          .eq('thread_id', threadId)
          .order('created_at', { ascending: true });

        if (messagesError) throw messagesError;
        setMessages(messages || []);
      }
    } catch (error) {
      console.error('Error fetching thread:', error);
    }
  };

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) return;

      const { data: profile } = await supabase
        .from('profiles')
        .select('*')
        .eq('id', user.id)
        .single();

      if (profile) {
        setUserProfile(profile);
      }

      const { data: homes } = await supabase
        .from('homes')
        .select('id, address, city, state')
        .eq('owner_id', user.id);

      if (homes) {
        setUserHomes(homes);
        
        const { data: inventory } = await supabase
          .from('inventory_items')
          .select('*')
          .in('home_id', homes.map(home => home.id));

        if (inventory) {
          setInventoryItems(inventory);
        }
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!input.trim() || loading) return;

    const userMessage = input.trim();
    setInput('');
    
    const newUserMessage: Message = {
      role: 'user',
      content: userMessage,
      thread_id: currentThreadId || undefined
    };
    
    setMessages(prev => [...prev, newUserMessage]);
    setLoading(true);

    try {
      const { data: { session } } = await supabase.auth.getSession();
      
      if (!session) {
        throw new Error('No authenticated session');
      }

      const response = await fetch('https://wqznjpzkejoxiokudivc.supabase.co/functions/v1/chat', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${session.access_token}`
        },
        body: JSON.stringify({
          message: userMessage,
          userProfile,
          userHomes,
          inventoryItems,
          threadId: currentThreadId
        }),
      });

      if (!response.ok) {
        throw new Error('Failed to get response');
      }

      const data = await response.json();
      
      // Save the thread ID if this is a new thread
      if (data.threadId && !currentThreadId) {
        setCurrentThreadId(data.threadId);
        
        // Save thread to database
        const { error: threadError } = await supabase
          .from('threads')
          .insert([{
            assistant_thread_id: data.threadId,
            user_id: session.user.id
          }]);

        if (threadError) throw threadError;
      }

      // Save messages to database
      const { error: userMessageError } = await supabase
        .from('assistant_messages')
        .insert([{
          thread_id: data.threadId,
          role: 'user',
          content: userMessage
        }]);

      if (userMessageError) throw userMessageError;

      // Process the response to add actions
      const actions = processResponseForActions(data.message);
      
      const assistantMessage: Message = {
        role: 'assistant',
        content: data.message,
        thread_id: data.threadId,
        assistant_message_id: data.messageId,
        run_id: data.runId,
        actions
      };
      
      setMessages(prev => [...prev, assistantMessage]);

      // Save assistant message
      const { error: assistantMessageError } = await supabase
        .from('assistant_messages')
        .insert([{
          thread_id: data.threadId,
          role: 'assistant',
          content: data.message,
          assistant_message_id: data.messageId,
          run_id: data.runId
        }]);

      if (assistantMessageError) throw assistantMessageError;
      
    } catch (error) {
      console.error('Error getting response:', error);
      const errorMessage: Message = {
        role: 'assistant',
        content: 'I apologize, but I encountered an error. Please try again later.'
      };
      setMessages(prev => [...prev, errorMessage]);
    } finally {
      setLoading(false);
    }
  };

  const processResponseForActions = (message: string) => {
    const actions: Message['actions'] = [];
    
    // Check if the message is about maintenance
    const maintenanceKeywords = [
      'maintenance',
      'maintenance plan',
      'tasks',
      'upcoming tasks',
      'scheduled maintenance',
      'maintenance schedule',
      'maintenance history'
    ];
    
    const hasMaintenanceKeywords = maintenanceKeywords.some(keyword => 
      message.toLowerCase().includes(keyword)
    );

    if (hasMaintenanceKeywords) {
      actions.push({ type: 'viewMaintenancePlan' });
    }

    // Check if the message is about inventory
    const inventoryKeywords = ['inventory', 'items', 'belongings', 'possessions'];
    const hasInventoryKeywords = inventoryKeywords.some(keyword => 
      message.toLowerCase().includes(keyword)
    );

    if (hasInventoryKeywords) {
      actions.push({ type: 'viewInventory' });

      // Check if the message mentions specific items
      inventoryItems.forEach(item => {
        if (message.toLowerCase().includes(item.name.toLowerCase())) {
          actions.push({
            type: 'viewInventoryItem',
            data: {
              itemId: item.id,
              itemName: item.name
            }
          });
        }
      });
    }

    // Check if the message indicates need for service
    const serviceKeywords = [
      'need service',
      'need help',
      'need repair',
      'broken',
      'not working',
      'fix',
      'repair',
      'service',
      'maintenance needed',
      'problem with',
      'issue with',
      'schedule service',
      'book service',
      'request service'
    ];

    const hasServiceKeywords = serviceKeywords.some(keyword =>
      message.toLowerCase().includes(keyword)
    );

    if (hasServiceKeywords) {
      actions.push({ type: 'createServiceRequest' });
    }

    return actions;
  };

  const renderSkeletonLoader = () => {
    return (
      <div className="space-y-8">
        {[1, 2, 3].map((i) => (
          <div key={i} className="flex items-start space-x-3">
            <div className="w-8 h-8 bg-gray-200 rounded-full animate-pulse" />
            <div className="flex-1">
              <div className="bg-gray-100 rounded-lg p-4">
                <div className="space-y-2">
                  <div className="h-4 bg-gray-200 rounded w-3/4 animate-pulse" />
                  <div className="h-4 bg-gray-200 rounded w-1/2 animate-pulse" />
                  <div className="h-4 bg-gray-200 rounded w-2/3 animate-pulse" />
                </div>
              </div>
              <div className="mt-2 flex space-x-2">
                <div className="h-8 bg-gray-200 rounded w-32 animate-pulse" />
                <div className="h-8 bg-gray-200 rounded w-32 animate-pulse" />
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="space-y-8">
      <header>
        <h1 className="text-3xl font-bold">HoneyBot Assistant</h1>
        <p className="text-gray-600">Your AI home management assistant</p>
      </header>

      <div className="bg-white rounded-xl shadow-sm border border-gray-100 min-h-[600px] flex flex-col">
        <div className="flex items-center space-x-3 p-4 border-b border-gray-100">
          <div className="w-10 h-10 bg-black rounded-full flex items-center justify-center overflow-hidden">
            {!avatarError ? (
              <img
                src="https://storage.googleapis.com/hdwebsite/images/HoneyBot%20Avatar.png"
                alt="HoneyBot"
                className="w-full h-full object-cover"
                onError={() => setAvatarError(true)}
              />
            ) : (
              <Bot className="w-6 h-6 text-white" />
            )}
          </div>
          <div>
            <h2 className="font-semibold">HoneyBot</h2>
            <p className="text-sm text-gray-600">AI Home Assistant</p>
          </div>
        </div>

        <div className="flex-1 overflow-y-auto p-4 space-y-4">
          {initialLoading ? (
            renderSkeletonLoader()
          ) : messages.length === 0 ? (
            <div className="text-center py-12">
              <Bot className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <h3 className="font-medium mb-2">Welcome to HoneyBot!</h3>
              <p className="text-gray-600">
                I'm here to help you manage your home. Ask me anything about maintenance,
                repairs, or your home management needs.
              </p>
            </div>
          ) : (
            <>
              {messages.map((message, index) => (
                <ChatMessage
                  key={message.id || index}
                  role={message.role}
                  content={message.content}
                  avatarUrl={message.role === 'assistant' ? "https://storage.googleapis.com/hdwebsite/images/HoneyBot%20Avatar.png" : undefined}
                  onAvatarError={() => setAvatarError(true)}
                  actions={message.actions}
                />
              ))}
              {loading && (
                <div className="flex items-start space-x-3">
                  <div className="w-8 h-8 bg-black rounded-full flex items-center justify-center overflow-hidden">
                    {!avatarError ? (
                      <img
                        src="https://storage.googleapis.com/hdwebsite/images/HoneyBot%20Avatar.png"
                        alt="HoneyBot"
                        className="w-full h-full object-cover"
                        onError={() => setAvatarError(true)}
                      />
                    ) : (
                      <Bot className="w-5 h-5 text-white" />
                    )}
                  </div>
                  <div className="flex-1 rounded-lg p-4 bg-gray-50">
                    <div className="flex items-center space-x-2">
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '0ms' }} />
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '200ms' }} />
                      <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce" style={{ animationDelay: '400ms' }} />
                    </div>
                  </div>
                </div>
              )}
            </>
          )}
          <div ref={messagesEndRef} />
        </div>

        <form onSubmit={handleSubmit} className="p-4 border-t border-gray-100">
          <div className="flex space-x-4">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
              className="flex-1 px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
              disabled={loading || initialLoading}
            />
            <button
              type="submit"
              disabled={loading || initialLoading || !input.trim()}
              className="px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              {loading ? (
                <Loader2 className="w-5 h-5 animate-spin" />
              ) : (
                <Send className="w-5 h-5" />
              )}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Assistant;