import React, { useState, useEffect } from 'react';
import { Receipt, Plus, X, Loader2, Calendar, DollarSign, Home as HomeIcon, TrendingUp } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface Expense {
  id: string;
  home_id: string;
  title: string;
  amount: number;
  date: string;
  category: string;
  description: string;
  receipt_url: string | null;
  created_at: string;
}

interface Home {
  id: string;
  address: string;
  city: string;
  state: string;
}

const expenseCategories = [
  'Maintenance',
  'Repairs',
  'Improvements',
  'Utilities',
  'Insurance',
  'Professional Services',
  'Supplies',
  'Other'
];

const Expenses = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [homes, setHomes] = useState<Home[]>([]);
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [selectedHome, setSelectedHome] = useState<string>('');
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    home_id: '',
    title: '',
    amount: '',
    date: new Date().toISOString().split('T')[0],
    category: 'Maintenance',
    description: '',
    receipt_url: ''
  });

  useEffect(() => {
    fetchHomes();
  }, []);

  useEffect(() => {
    if (selectedHome) {
      fetchExpenses();
    }
  }, [selectedHome]);

  const fetchHomes = async () => {
    try {
      const { data, error } = await supabase
        .from('homes')
        .select('id, address, city, state');
      
      if (error) throw error;
      setHomes(data || []);
      
      if (data && data.length > 0) {
        setSelectedHome(data[0].id);
        setFormData(prev => ({ ...prev, home_id: data[0].id }));
      }
    } catch (error) {
      console.error('Error fetching homes:', error);
    }
  };

  const fetchExpenses = async () => {
    try {
      const { data, error } = await supabase
        .from('expenses')
        .select('*')
        .eq('home_id', selectedHome)
        .order('date', { ascending: false });

      if (error) throw error;
      setExpenses(data || []);
    } catch (error) {
      console.error('Error fetching expenses:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError('');

    try {
      const { error } = await supabase
        .from('expenses')
        .insert([{
          ...formData,
          amount: parseFloat(formData.amount),
          home_id: selectedHome
        }]);

      if (error) throw error;

      setFormData({
        home_id: selectedHome,
        title: '',
        amount: '',
        date: new Date().toISOString().split('T')[0],
        category: 'Maintenance',
        description: '',
        receipt_url: ''
      });
      setIsFormOpen(false);
      fetchExpenses();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const calculateTotal = () => {
    return expenses.reduce((sum, expense) => sum + expense.amount, 0);
  };

  const calculateYearlyStats = () => {
    const currentYear = new Date().getFullYear();
    const yearExpenses = expenses.filter(expense => 
      new Date(expense.date).getFullYear() === currentYear
    );

    const total = yearExpenses.reduce((sum, expense) => sum + expense.amount, 0);
    
    const byCategory = yearExpenses.reduce((acc, expense) => {
      acc[expense.category] = (acc[expense.category] || 0) + expense.amount;
      return acc;
    }, {} as Record<string, number>);

    const topCategory = Object.entries(byCategory)
      .sort(([,a], [,b]) => b - a)[0];

    return {
      total,
      topCategory: topCategory ? {
        name: topCategory[0],
        amount: topCategory[1]
      } : null
    };
  };

  const yearlyStats = calculateYearlyStats();

  return (
    <div className="space-y-8">
      <header className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Expenses</h1>
          <p className="text-gray-600">Track your home maintenance expenses</p>
        </div>
        <button
          onClick={() => setIsFormOpen(true)}
          className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
        >
          <Plus className="w-4 h-4" />
          <span>Add Expense</span>
        </button>
      </header>

      {!loading && expenses.length > 0 && (
        <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
          <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-6">
            <div className="flex items-center space-x-3 mb-4">
              <TrendingUp className="w-6 h-6" />
              <h2 className="text-xl font-semibold">This Year</h2>
            </div>
            <div className="space-y-4">
              <div>
                <p className="text-gray-600">Total Spent</p>
                <p className="text-3xl font-bold">{formatCurrency(yearlyStats.total)}</p>
              </div>
              {yearlyStats.topCategory && (
                <div>
                  <p className="text-gray-600">Top Category</p>
                  <div className="flex items-center justify-between">
                    <p className="font-medium">{yearlyStats.topCategory.name}</p>
                    <p className="font-medium">{formatCurrency(yearlyStats.topCategory.amount)}</p>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      <div className="bg-white rounded-lg shadow-sm border border-gray-100">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-3">
              <Receipt className="w-6 h-6" />
              <h2 className="text-xl font-semibold">Expense History</h2>
            </div>
            <select
              value={selectedHome}
              onChange={(e) => setSelectedHome(e.target.value)}
              className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
            >
              {homes.map((home) => (
                <option key={home.id} value={home.id}>
                  {home.address}, {home.city}
                </option>
              ))}
            </select>
          </div>

          {loading ? (
            <div className="flex justify-center py-8">
              <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
            </div>
          ) : expenses.length === 0 ? (
            <div className="text-center py-8">
              <div className="space-y-3">
                <Receipt className="w-8 h-8 mx-auto text-gray-400" />
                <div>
                  <p className="font-medium">No Expenses</p>
                  <p className="text-gray-600">Start tracking your home maintenance expenses</p>
                </div>
              </div>
            </div>
          ) : (
            <div className="space-y-6">
              <div className="overflow-x-auto">
                <table className="w-full">
                  <thead>
                    <tr className="border-b border-gray-200">
                      <th className="text-left py-3 px-4">Date</th>
                      <th className="text-left py-3 px-4">Title</th>
                      <th className="text-left py-3 px-4">Category</th>
                      <th className="text-right py-3 px-4">Amount</th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-gray-100">
                    {expenses.map((expense) => (
                      <tr key={expense.id} className="hover:bg-gray-50">
                        <td className="py-3 px-4">
                          {new Date(expense.date).toLocaleDateString()}
                        </td>
                        <td className="py-3 px-4">
                          <div>
                            <div className="font-medium">{expense.title}</div>
                            {expense.description && (
                              <div className="text-sm text-gray-600">{expense.description}</div>
                            )}
                          </div>
                        </td>
                        <td className="py-3 px-4">
                          <span className="inline-block px-2 py-1 text-sm bg-gray-100 rounded-md">
                            {expense.category}
                          </span>
                        </td>
                        <td className="py-3 px-4 text-right font-medium">
                          {formatCurrency(expense.amount)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr className="border-t border-gray-200 font-medium">
                      <td colSpan={3} className="py-3 px-4 text-right">Total</td>
                      <td className="py-3 px-4 text-right">{formatCurrency(calculateTotal())}</td>
                    </tr>
                  </tfoot>
                </table>
              </div>
            </div>
          )}
        </div>
      </div>

      {isFormOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Add Expense</h2>
              <button
                onClick={() => setIsFormOpen(false)}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Enter expense title"
                />
              </div>

              <div className="grid grid-cols-2 gap-4">
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Amount
                  </label>
                  <div className="relative">
                    <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                    <input
                      type="number"
                      step="0.01"
                      min="0"
                      value={formData.amount}
                      onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                      required
                      className="w-full pl-9 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                      placeholder="0.00"
                    />
                  </div>
                </div>
                <div>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Date
                  </label>
                  <div className="relative">
                    <Calendar className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
                    <input
                      type="date"
                      value={formData.date}
                      onChange={(e) => setFormData({ ...formData, date: e.target.value })}
                      required
                      className="w-full pl-9 pr-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                    />
                  </div>
                </div>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Category
                </label>
                <select
                  value={formData.category}
                  onChange={(e) => setFormData({ ...formData, category: e.target.value })}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                >
                  {expenseCategories.map((category) => (
                    <option key={category} value={category}>
                      {category}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  rows={3}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Optional description"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Receipt URL
                </label>
                <input
                  type="url"
                  value={formData.receipt_url}
                  onChange={(e) => setFormData({ ...formData, receipt_url: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Optional receipt URL"
                />
              </div>

              {error && (
                <p className="text-red-500 text-sm">{error}</p>
              )}

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsFormOpen(false)}
                  className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={submitting}
                  className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors disabled:opacity-50"
                >
                  {submitting ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Adding...</span>
                    </>
                  ) : (
                    <span>Add Expense</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Expenses;