import { supabase } from './supabase';

interface EmailNotification {
  id: string;
  service_request_id: string;
  recipient: string;
  subject: string;
  body: string;
  sent_at: string;
  status: string;
}

interface NewUserPayload {
  fullname: string;
  email: string;
  phoneNumber: string;
}

const GOOGLE_CLOUD_FUNCTION_URL = 'https://hd-resend-service-512019994511.us-central1.run.app';

export const getEmailNotifications = async (serviceRequestId: string): Promise<EmailNotification[]> => {
  const { data, error } = await supabase
    .from('email_notifications')
    .select('*')
    .eq('service_request_id', serviceRequestId)
    .order('sent_at', { ascending: false });

  if (error) {
    throw error;
  }

  return data || [];
};

export const sendServiceRequestEmail = async (notification: EmailNotification): Promise<void> => {
  try {
    const response = await fetch(GOOGLE_CLOUD_FUNCTION_URL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        to: notification.recipient,
        subject: notification.subject,
        text: notification.body,
      }),
    });

    if (!response.ok) {
      throw new Error(`Failed to send email: ${response.statusText}`);
    }

    // Update notification status
    const { error: updateError } = await supabase
      .from('email_notifications')
      .update({ status: 'sent' })
      .eq('id', notification.id);

    if (updateError) {
      throw updateError;
    }

    console.log('Email sent successfully');
  } catch (error) {
    console.error('Failed to send email:', error);
    
    // Update notification status to failed
    await supabase
      .from('email_notifications')
      .update({ status: 'failed' })
      .eq('id', notification.id);

    throw error;
  }
};

export const resendServiceRequestEmail = async (serviceRequestId: string): Promise<void> => {
  const { data: request, error: requestError } = await supabase
    .from('service_requests')
    .select(`
      *,
      homes (
        address,
        city,
        state
      )
    `)
    .eq('id', serviceRequestId)
    .single();

  if (requestError) {
    throw requestError;
  }

  const emailBody = `
A new service request has been submitted:

Title: ${request.title}
Description: ${request.description}
Priority: ${request.priority}
Status: ${request.status}
Location: ${request.homes.address}, ${request.homes.city}, ${request.homes.state}

Please review and respond accordingly.
  `.trim();

  const { data: notification, error: notificationError } = await supabase
    .from('email_notifications')
    .insert([{
      service_request_id: serviceRequestId,
      recipient: 'service@honeydone.com',
      subject: `New Service Request: ${request.title}`,
      body: emailBody
    }])
    .select()
    .single();

  if (notificationError) {
    throw notificationError;
  }

  // Send the email
  await sendServiceRequestEmail(notification);
};

export const sendNewUserRegistrationEmail = async (payload: NewUserPayload): Promise<void> => {
  const subject = `New User Registration: ${payload.fullname}`;
  const body = `
A new user has registered:

Full Name: ${payload.fullname}
Email: ${payload.email}
Phone: ${payload.phoneNumber}
  `.trim();

  // Insert the email notification record
  const { data: notification, error: notificationError } = await supabase
    .from('email_notifications')
    .insert([{
      recipient: 'service@honeydone.com',
      subject,
      body
    }])
    .select()
    .single();

  if (notificationError) {
    throw notificationError;
  }

  // Send the email using the existing function
  await sendServiceRequestEmail(notification);
};