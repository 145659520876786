import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import { Home, Users, UserPlus, CheckCircle2, ArrowRight, Loader2 } from 'lucide-react';
import { supabase, createHubspotContact } from '../lib/supabase';
import { sendNewUserRegistrationEmail } from '../lib/email';

interface FormData {
  fullName: string;
  email: string;
  password: string;
  phoneNumber: string;
  address: string;
  city: string;
  state: string;
  zipCode: string;
  squareFootage: string;
  yearBuilt: string;
  interests: string[];
  currentServices: string[];
  desiredServices: string[];
}

const Onboarding = () => {
  const navigate = useNavigate();
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState<FormData>({
    fullName: '',
    email: '',
    password: '',
    phoneNumber: '',
    address: '',
    city: '',
    state: '',
    zipCode: '',
    squareFootage: '',
    yearBuilt: '',
    interests: [],
    currentServices: [],
    desiredServices: []
  });

  const services = [
    { id: 'landscaping', label: 'Landscaping' },
    { id: 'housekeeping', label: 'Housekeeping' },
    { id: 'pest_control', label: 'Pest Control' },
    { id: 'pool_maintenance', label: 'Pool Maintenance' },
    { id: 'hvac', label: 'HVAC Service' },
    { id: 'plumbing', label: 'Plumbing' },
    { id: 'electrical', label: 'Electrical' },
    { id: 'roofing', label: 'Roofing' },
    { id: 'cleaning', label: 'Deep Cleaning' }
  ];

  const handleServiceToggle = (service: string, type: 'current' | 'desired') => {
    const field = type === 'current' ? 'currentServices' : 'desiredServices';
    setFormData(prev => ({
      ...prev,
      [field]: prev[field].includes(service)
        ? prev[field].filter(s => s !== service)
        : [...prev[field], service]
    }));
  };

  const handleSubmit = async () => {
    try {
      setLoading(true);
      setError('');

      // Create user account
      const { data: authData, error: authError } = await supabase.auth.signUp({
        email: formData.email,
        password: formData.password,
      });

      if (authError) throw authError;

      const user = authData.user;
      if (!user) throw new Error('No user data returned');

      // Create profile with default home_manager
      const { error: profileError } = await supabase
        .from('profiles')
        .upsert({
          id: user.id,
          full_name: formData.fullName,
          phone_number: formData.phoneNumber,
          interests: formData.interests,
          current_services: formData.currentServices,
          desired_services: formData.desiredServices,
          plan: 'free',
          home_manager: 'Unassigned' // Set default home manager
        });

      if (profileError) throw profileError;

      // Create home
      const { error: homeError } = await supabase
        .from('homes')
        .insert({
          owner_id: user.id,
          address: formData.address,
          city: formData.city,
          state: formData.state,
          zip_code: formData.zipCode,
          square_footage: parseInt(formData.squareFootage) || null,
          year_built: parseInt(formData.yearBuilt) || null
        });

      if (homeError) throw homeError;

      // Create Hubspot contact
      try {
        await createHubspotContact({
          email: formData.email,
          fullName: formData.fullName,
          phoneNumber: formData.phoneNumber,
          userId: user.id
        });

        // Send new user registration email
        await sendNewUserRegistrationEmail({
          fullname: formData.fullName,
          email: formData.email,
          phoneNumber: formData.phoneNumber
        });
      } catch (hubspotError) {
        // Log the error but don't fail the signup process
        console.error('Error creating Hubspot contact:', hubspotError);
      }

      setCurrentStep(4); // Move to success step
    } catch (error: any) {
      console.error('Error during onboarding:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (currentStep) {
      case 0:
        return (
          <div className=" space-y-8">
            <div className="space-y-3">
              <h2 className="text-3xl font-bold">Welcome to HunnyDone</h2>
              <p className="text-gray-600 text-lg max-w-2xl mx-auto">
                Your complete home management solution. Keep track of everything your home needs, from regular maintenance to service providers.
              </p>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="p-6 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors">
                <Home className="w-8 h-8 mb-4" />
                <h3 className="font-semibold mb-2">Digital Record Keeping</h3>
                <p className="text-gray-600 text-sm">
                  Track all your home expenses, maintenance history, and important documents in one secure place
                </p>
              </div>
              <div className="p-6 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors">
                <Users className="w-8 h-8 mb-4" />
                <h3 className="font-semibold mb-2">Service Provider Management</h3>
                <p className="text-gray-600 text-sm">
                  Easily manage and coordinate with all your home service providers
                </p>
              </div>
              <div className="p-6 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors">
                <UserPlus className="w-8 h-8 mb-4" />
                <h3 className="font-semibold mb-2">Proactive Maintenance</h3>
                <p className="text-gray-600 text-sm">
                  Stay ahead of home maintenance with personalized schedules and reminders
                </p>
              </div>
            </div>
            <div className="bg-gray-50 rounded-xl p-6 max-w-2xl mx-auto">
              <h4 className="font-semibold mb-2">Why Choose HunnyDone?</h4>
              <p className="text-gray-600 text-sm">
                We help you protect your biggest investment by making home maintenance simple and organized. 
                Whether you manage everything yourself or work with service providers, HunnyDone keeps you 
                on top of your home's needs.
              </p>
            </div>
          </div>
        );

      case 1:
        return (
          <div className="space-y-6">
            <div className="space-y-2">
              <h2 className="text-2xl font-bold">Tell us about your home</h2>
              <p className="text-gray-600">
                We'll use this information to customize your maintenance plan
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Street Address
                </label>
                <input
                  type="text"
                  value={formData.address}
                  onChange={(e) => setFormData({ ...formData, address: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Enter your street address"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  City
                </label>
                <input
                  type="text"
                  value={formData.city}
                  onChange={(e) => setFormData({ ...formData, city: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Enter your city"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  State
                </label>
                <input
                  type="text"
                  value={formData.state}
                  onChange={(e) => setFormData({ ...formData, state: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Enter your state"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  ZIP Code
                </label>
                <input
                  type="text"
                  value={formData.zipCode}
                  onChange={(e) => setFormData({ ...formData, zipCode: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Enter your ZIP code"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Square Footage
                </label>
                <input
                  type="number"
                  value={formData.squareFootage}
                  onChange={(e) => setFormData({ ...formData, squareFootage: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Optional"
                />
              </div>
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Year Built
                </label>
                <input
                  type="number"
                  value={formData.yearBuilt}
                  onChange={(e) => setFormData({ ...formData, yearBuilt: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Optional"
                />
              </div>
            </div>
          </div>
        );

      case 2:
        return (
          <div className="space-y-6">
            <div className="space-y-2">
              <h2 className="text-2xl font-bold">Your Home Services</h2>
              <p className="text-gray-600">
                Tell us about the services you currently use and what you're interested in
              </p>
            </div>

            <div className="space-y-6">
              <div>
                <h3 className="font-medium mb-4">Current Services</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                  {services.map((service) => (
                    <button
                      key={service.id}
                      onClick={() => handleServiceToggle(service.id, 'current')}
                      className={`p-4 rounded-lg text-left transition-colors ${
                        formData.currentServices.includes(service.id)
                          ? 'bg-black text-white'
                          : 'bg-gray-50 hover:bg-gray-100'
                      }`}
                    >
                      <span className="block font-medium">{service.label}</span>
                    </button>
                  ))}
                </div>
              </div>

              <div>
                <h3 className="font-medium mb-4">Interested In</h3>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-3">
                  {services.map((service) => {
                    const isCurrentService = formData.currentServices.includes(service.id);
                    return (
                      <button
                        key={service.id}
                        onClick={() => !isCurrentService && handleServiceToggle(service.id, 'desired')}
                        disabled={isCurrentService}
                        className={`p-4 rounded-lg text-left transition-colors relative ${
                          isCurrentService
                            ? 'bg-gray-100 cursor-not-allowed'
                            : formData.desiredServices.includes(service.id)
                            ? 'bg-black text-white'
                            : 'bg-gray-50 hover:bg-gray-100'
                        }`}
                      >
                        <span className="block font-medium">{service.label}</span>
                        {isCurrentService && (
                          <span className="text-sm text-gray-500 mt-1 block">
                            Already a current service
                          </span>
                        )}
                      </button>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        );

      case 3:
        return (
          <div className="space-y-6">
            <div className="space-y-2">
              <h2 className="text-2xl font-bold">Create Your Account</h2>
              <p className="text-gray-600">
                Set up your account to get started with HunnyDone
              </p>
            </div>

            <div className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Full Name
                </label>
                <input
                  type="text"
                  value={formData.fullName}
                  onChange={(e) => setFormData({ ...formData, fullName: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Enter your full name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Phone Number
                </label>
                <input
                  type="tel"
                  value={formData.phoneNumber}
                  onChange={(e) => setFormData({ ...formData, phoneNumber: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Enter your phone number"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Email
                </label>
                <input
                  type="email"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Enter your email"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Password
                </label>
                <input
                  type="password"
                  value={formData.password}
                  onChange={(e) => setFormData({ ...formData, password: e.target.value })}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Create a password"
                />
              </div>
            </div>
          </div>
        );

      case 4:
        return (
          <div className="text-center space-y-6">
            <div className="w-16 h-16 bg-green-50 rounded-full flex items-center justify-center mx-auto">
              <CheckCircle2 className="w-8 h-8 text-green-600" />
            </div>
            <div className="space-y-2">
              <h2 className="text-2xl font-bold">Welcome to HunnyDone!</h2>
              <p className="text-gray-600">
                Your account has been created successfully. Let's get started with your home maintenance plan.
              </p>
            </div>
            <button
              onClick={() => navigate('/plans')}
              className="inline-flex items-center space-x-2 px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
              <span>Continue to Plans</span>
              <ArrowRight className="w-4 h-4" />
            </button>
          </div>
        );
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 flex items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
      <div className="max-w-3xl w-full space-y-8">
        {currentStep < 4 && (
          <div className="flex items-center justify-center space-x-2 mb-8">
            {[0, 1, 2, 3].map((step) => (
              <div
                key={step}
                className={`h-2 w-16 rounded-full transition-colors ${
                  step <= currentStep ? 'bg-black' : 'bg-gray-200'
                }`}
              />
            ))}
          </div>
        )}

        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
          {renderStep()}

          {error && (
            <p className="text-red-500 text-sm text-center mt-4">{error}</p>
          )}

          {currentStep < 4 && (
            <div className="flex justify-between mt-8">
              <button
                onClick={() => setCurrentStep(prev => prev - 1)}
                disabled={currentStep === 0}
                className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800 disabled:opacity-50"
              >
                Back
              </button>
              <button
                onClick={() => {
                  if (currentStep === 3) {
                    handleSubmit();
                  } else {
                    setCurrentStep(prev => prev + 1);
                  }
                }}
                disabled={loading}
                className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors disabled:opacity-50"
              >
                {loading ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    <span>Creating Account...</span>
                  </>
                ) : (
                  <>
                    <span>{currentStep === 3 ? 'Create Account' : 'Continue'}</span>
                    <ArrowRight className="w-4 h-4" />
                  </>
                )}
              </button>
            </div>
          )}
        </div>

        {currentStep < 4 && (
          <p className="text-center text-sm text-gray-600">
            Already have an account?{' '}
            <Link to="/login" className="font-medium text-black hover:text-gray-800">
              Sign in
            </Link>
          </p>
        )}
      </div>
    </div>
  );
};

export default Onboarding;