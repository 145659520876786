import React, { useState, useEffect } from 'react';
import { MessageSquare, Loader2, Calendar, Home as HomeIcon, AlertTriangle, Clock, CheckCircle2, X } from 'lucide-react';
import { supabase } from '../../lib/supabase';

interface ServiceRequest {
  id: string;
  title: string;
  description: string;
  priority: 'low' | 'normal' | 'high' | 'urgent';
  status: 'pending' | 'in_progress' | 'completed' | 'cancelled';
  created_at: string;
  home: {
    address: string;
    city: string;
    state: string;
  };
}

const ServiceRequestsTab = () => {
  const [loading, setLoading] = useState(true);
  const [requests, setRequests] = useState<ServiceRequest[]>([]);
  const [error, setError] = useState<string | null>(null);
  const [statusFilter, setStatusFilter] = useState<ServiceRequest['status'] | 'all'>('all');
  const [updatingId, setUpdatingId] = useState<string | null>(null);
  const [showStatusModal, setShowStatusModal] = useState(false);
  const [selectedRequest, setSelectedRequest] = useState<ServiceRequest | null>(null);

  useEffect(() => {
    fetchRequests();
  }, []);

  const fetchRequests = async () => {
    try {
      setLoading(true);
      const { data, error } = await supabase
        .from('service_requests')
        .select(`
          id,
          title,
          description,
          priority,
          status,
          created_at,
          home:homes (
            address,
            city,
            state
          )
        `)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setRequests(data || []);
    } catch (error: any) {
      console.error('Error fetching service requests:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const handleStatusUpdate = async (requestId: string, newStatus: ServiceRequest['status']) => {
    try {
      setUpdatingId(requestId);
      const { error } = await supabase
        .from('service_requests')
        .update({ status: newStatus })
        .eq('id', requestId);

      if (error) throw error;
      
      // Update local state
      setRequests(prev => prev.map(request => 
        request.id === requestId 
          ? { ...request, status: newStatus }
          : request
      ));
      
      setShowStatusModal(false);
      setSelectedRequest(null);
    } catch (error: any) {
      console.error('Error updating request status:', error);
      setError(error.message);
    } finally {
      setUpdatingId(null);
    }
  };

  const getPriorityColor = (priority: ServiceRequest['priority']) => {
    switch (priority) {
      case 'urgent': return 'text-red-600 bg-red-50';
      case 'high': return 'text-orange-600 bg-orange-50';
      case 'normal': return 'text-blue-600 bg-blue-50';
      case 'low': return 'text-green-600 bg-green-50';
      default: return 'text-gray-600 bg-gray-50';
    }
  };

  const getStatusColor = (status: ServiceRequest['status']) => {
    switch (status) {
      case 'completed': return 'text-green-600 bg-green-50';
      case 'in_progress': return 'text-blue-600 bg-blue-50';
      case 'cancelled': return 'text-gray-600 bg-gray-50';
      default: return 'text-orange-600 bg-orange-50';
    }
  };

  const filteredRequests = statusFilter === 'all'
    ? requests
    : requests.filter(request => request.status === statusFilter);

  if (loading) {
    return (
      <div className="flex justify-center py-8">
        <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
      </div>
    );
  }

  if (error) {
    return (
      <div className="bg-red-50 text-red-600 p-4 rounded-lg">
        <p className="font-medium">Error loading service requests</p>
        <p className="text-sm">{error}</p>
      </div>
    );
  }

  return (
    <>
      <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-3">
            <MessageSquare className="w-6 h-6 text-gray-400" />
            <h2 className="text-xl font-semibold">Service Requests</h2>
          </div>
          <div className="flex items-center space-x-4">
            <select
              value={statusFilter}
              onChange={(e) => setStatusFilter(e.target.value as ServiceRequest['status'] | 'all')}
              className="px-3 py-1.5 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black text-sm"
            >
              <option value="all">All Statuses</option>
              <option value="pending">Pending</option>
              <option value="in_progress">In Progress</option>
              <option value="completed">Completed</option>
              <option value="cancelled">Cancelled</option>
            </select>
            <button
              onClick={fetchRequests}
              disabled={loading}
              className="flex items-center space-x-2 px-3 py-1.5 text-gray-600 hover:text-gray-800 disabled:opacity-50"
            >
              {loading ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <span>Refresh</span>
              )}
            </button>
          </div>
        </div>

        {filteredRequests.length === 0 ? (
          <div className="text-center py-8">
            <AlertTriangle className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium mb-2">No Service Requests Found</h3>
            <p className="text-gray-600">
              {statusFilter === 'all'
                ? 'There are no service requests in the system.'
                : `There are no ${statusFilter} service requests.`}
            </p>
          </div>
        ) : (
          <div className="space-y-4">
            {filteredRequests.map((request) => (
              <div
                key={request.id}
                className="p-4 bg-gray-50 rounded-lg hover:bg-gray-100 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div className="space-y-2">
                    <h3 className="font-medium">{request.title}</h3>
                    <p className="text-gray-600">{request.description}</p>
                    <div className="flex items-center space-x-4">
                      <div className="flex items-center space-x-2">
                        <HomeIcon className="w-4 h-4 text-gray-400" />
                        <span className="text-sm text-gray-600">
                          {request.home.address}, {request.home.city}, {request.home.state}
                        </span>
                      </div>
                      <div className="flex items-center space-x-2">
                        <Clock className="w-4 h-4 text-gray-400" />
                        <span className="text-sm text-gray-600">
                          {new Date(request.created_at).toLocaleDateString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <span className={`px-2 py-1 rounded-md text-sm ${getPriorityColor(request.priority)}`}>
                      {request.priority.charAt(0).toUpperCase() + request.priority.slice(1)}
                    </span>
                    <button
                      onClick={() => {
                        setSelectedRequest(request);
                        setShowStatusModal(true);
                      }}
                      disabled={updatingId === request.id}
                      className={`px-2 py-1 rounded-md text-sm transition-colors ${getStatusColor(request.status)} hover:opacity-80`}
                    >
                      {updatingId === request.id ? (
                        <Loader2 className="w-4 h-4 animate-spin" />
                      ) : (
                        request.status.split('_').map(word => 
                          word.charAt(0).toUpperCase() + word.slice(1)
                        ).join(' ')
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Status Update Modal */}
      {showStatusModal && selectedRequest && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h3 className="text-lg font-semibold">Update Request Status</h3>
              <button
                onClick={() => {
                  setShowStatusModal(false);
                  setSelectedRequest(null);
                }}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="space-y-4">
              <div className="text-sm text-gray-600">
                <p className="font-medium">{selectedRequest.title}</p>
                <p>Current Status: {selectedRequest.status.split('_').map(word => 
                  word.charAt(0).toUpperCase() + word.slice(1)
                ).join(' ')}</p>
              </div>

              <div className="grid grid-cols-2 gap-3">
                {(['pending', 'in_progress', 'completed', 'cancelled'] as const).map((status) => (
                  <button
                    key={status}
                    onClick={() => handleStatusUpdate(selectedRequest.id, status)}
                    disabled={status === selectedRequest.status || updatingId === selectedRequest.id}
                    className={`p-3 rounded-lg flex items-center justify-center space-x-2 transition-colors ${
                      status === selectedRequest.status
                        ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                        : getStatusColor(status) + ' hover:opacity-80'
                    }`}
                  >
                    <CheckCircle2 className="w-4 h-4" />
                    <span>
                      {status.split('_').map(word => 
                        word.charAt(0).toUpperCase() + word.slice(1)
                      ).join(' ')}
                    </span>
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ServiceRequestsTab;