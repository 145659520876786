import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Home } from 'lucide-react';

interface NavItem {
  icon: typeof Home;
  label: string;
  path: string;
}

interface NavbarProps {
  items: NavItem[];
}

const Navbar: React.FC<NavbarProps> = ({ items }) => {
  const location = useLocation();

  return (
    <nav className="bg-white border-b border-gray-200">
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-between h-16">
          <div className="flex items-center">
            <Link to="/dashboard" className="flex items-center space-x-2">
               <img 
                src="https://storage.googleapis.com/hdwebsite/images/hd.png" 
                alt="HoneyDone Logo" 
                className="h-8 w-auto"
              />
              <span className="font-semibold text-xl">HoneyDone</span>
            </Link>
          </div>
          
          <div className="hidden md:flex items-center space-x-8">
            {items.map((item) => {
              const Icon = item.icon;
              const isActive = location.pathname === item.path;
              
              return (
                <Link
                  key={item.path}
                  to={item.path}
                  className={`flex items-center space-x-1 px-3 py-2 rounded-md text-sm font-medium
                    ${isActive 
                      ? 'text-black bg-gray-100' 
                      : 'text-gray-600 hover:text-black hover:bg-gray-50'
                    }`}
                >
                  <Icon className="w-4 h-4" />
                  {/*
                    <span>{item.label}</span>
                        */}
                </Link>
              );
            })}
          </div>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;