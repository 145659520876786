import React, { useState, useEffect } from 'react';
import { Calendar, CheckCircle2, ArrowRight, X, Loader2, AlertTriangle, Clock, Wrench, Settings } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface Home {
  id: string;
  address: string;
  city: string;
  state: string;
}

interface HomeSystem {
  id: string;
  home_id: string;
  heating_systems: string[];
  cooling_systems: string[];
  appliances: string[];
  other_systems: string[];
  upgrades: string[];
  outdoor_features: string[];
}

interface MaintenanceTask {
  id: string;
  system_type: string;
  system_name: string;
  task_name: string;
  description: string;
  frequency_months: number;
  estimated_duration_minutes: number;
  priority: string;
  professional_required: boolean;
}

const systemOptions = {
  heating_systems: [
    { id: 'furnace', label: 'Furnace' },
    { id: 'heat_pump', label: 'Heat Pump' },
    { id: 'boiler', label: 'Boiler' },
    { id: 'baseboard', label: 'Electric Baseboard' },
    { id: 'fireplace', label: 'Fireplace' }
  ],
  cooling_systems: [
    { id: 'central_ac', label: 'Central AC' },
    { id: 'mini_split', label: 'Mini Split' },
    { id: 'window_units', label: 'Window Units' },
    { id: 'swamp_cooler', label: 'Swamp Cooler' }
  ],
  appliances: [
    { id: 'washer', label: 'Washer' },
    { id: 'dryer', label: 'Dryer' },
    { id: 'dishwasher', label: 'Dishwasher' },
    { id: 'refrigerator', label: 'Refrigerator' },
    { id: 'oven', label: 'Oven/Range' },
    { id: 'microwave', label: 'Built-in Microwave' },
    { id: 'garbage_disposal', label: 'Garbage Disposal' }
  ],
  other_systems: [
    { id: 'generator', label: 'Generator' },
    { id: 'water_softener', label: 'Water Softener' },
    { id: 'water_filter', label: 'Water Filtration System' },
    { id: 'sump_pump', label: 'Sump Pump' },
    { id: 'security_system', label: 'Security System' }
  ],
  upgrades: [
    { id: 'solar_panels', label: 'Solar Panels' },
    { id: 'ev_charger', label: 'EV Charger' },
    { id: 'electronic_locks', label: 'Electronic Locks' },
    { id: 'smart_thermostat', label: 'Smart Thermostat' },
    { id: 'whole_house_fan', label: 'Whole House Fan' }
  ],
  outdoor_features: [
    { id: 'gutters', label: 'Gutters' },
    { id: 'pool', label: 'Pool' },
    { id: 'hot_tub', label: 'Hot Tub' },
    { id: 'sprinklers', label: 'Sprinkler System' },
    { id: 'deck', label: 'Deck/Patio' },
    { id: 'fence', label: 'Fence' }
  ]
};

const MaintenancePlan = () => {
  const [homes, setHomes] = useState<Home[]>([]);
  const [selectedHome, setSelectedHome] = useState<string>('');
  const [isSetupOpen, setIsSetupOpen] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [systemData, setSystemData] = useState<Partial<HomeSystem>>({
    heating_systems: [],
    cooling_systems: [],
    appliances: [],
    other_systems: [],
    upgrades: [],
    outdoor_features: []
  });
  const [maintenanceTasks, setMaintenanceTasks] = useState<MaintenanceTask[]>([]);
  const [tasksLoading, setTasksLoading] = useState(false);

  useEffect(() => {
    fetchHomes();
  }, []);

  const fetchHomes = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('No authenticated user');
      }

      const { data: homesData, error: homesError } = await supabase
        .from('homes')
        .select('id, address, city, state')
        .eq('owner_id', user.id);

      if (homesError) throw homesError;
      setHomes(homesData || []);

      if (homesData && homesData.length > 0) {
        setSelectedHome(homesData[0].id);
        fetchHomeSystem(homesData[0].id);
      }
    } catch (error) {
      console.error('Error fetching homes:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchHomeSystem = async (homeId: string) => {
    try {
      const { data, error } = await supabase
        .from('home_systems')
        .select('*')
        .eq('home_id', homeId);
      
      if (error) throw error;
      
      // Handle the case where no data exists
      if (data && data.length > 0) {
        setSystemData({
          heating_systems: data[0].heating_systems || [],
          cooling_systems: data[0].cooling_systems || [],
          appliances: data[0].appliances || [],
          other_systems: data[0].other_systems || [],
          upgrades: data[0].upgrades || [],
          outdoor_features: data[0].outdoor_features || []
        });
      } else {
        // Reset to empty arrays if no data exists
        setSystemData({
          heating_systems: [],
          cooling_systems: [],
          appliances: [],
          other_systems: [],
          upgrades: [],
          outdoor_features: []
        });
      }
    } catch (error) {
      console.error('Error fetching home system:', error);
      // Reset to empty arrays on error
      setSystemData({
        heating_systems: [],
        cooling_systems: [],
        appliances: [],
        other_systems: [],
        upgrades: [],
        outdoor_features: []
      });
    }
  };

  const fetchMaintenanceTasks = async () => {
    setTasksLoading(true);
    try {
      const allSystems = [
        ...systemData.heating_systems?.map(s => ({ type: 'heating', name: s })) || [],
        ...systemData.cooling_systems?.map(s => ({ type: 'cooling', name: s })) || [],
        ...systemData.appliances?.map(s => ({ type: 'appliance', name: s })) || [],
        ...systemData.other_systems?.map(s => ({ type: 'other', name: s })) || [],
        ...systemData.upgrades?.map(s => ({ type: 'upgrade', name: s })) || [],
        ...systemData.outdoor_features?.map(s => ({ type: 'outdoor', name: s })) || []
      ];

      if (allSystems.length === 0) {
        setMaintenanceTasks([]);
        return;
      }

      const { data, error } = await supabase
        .from('maintenance_templates')
        .select('*')
        .in('system_type', allSystems.map(s => s.type))
        .in('system_name', allSystems.map(s => s.name));

      if (error) throw error;
      setMaintenanceTasks(data || []);
    } catch (error) {
      console.error('Error fetching maintenance tasks:', error);
    } finally {
      setTasksLoading(false);
    }
  };

  useEffect(() => {
    if (selectedHome) {
      fetchHomeSystem(selectedHome);
    }
  }, [selectedHome]);

  useEffect(() => {
    if (Object.values(systemData).some(arr => arr?.length > 0)) {
      fetchMaintenanceTasks();
    }
  }, [systemData]);

  const handleHomeChange = (homeId: string) => {
    setSelectedHome(homeId);
    fetchHomeSystem(homeId);
  };

  const handleSystemToggle = (type: keyof typeof systemOptions, id: string) => {
    setSystemData(prev => ({
      ...prev,
      [type]: prev[type]?.includes(id)
        ? prev[type]?.filter(item => item !== id)
        : [...(prev[type] || []), id]
    }));
  };

  const handleSubmit = async () => {
    if (!selectedHome) return;
    setSubmitting(true);
    setError('');

    try {
      const { error } = await supabase
        .from('home_systems')
        .upsert({
          home_id: selectedHome,
          ...systemData
        }, {
          onConflict: 'home_id'
        });

      if (error) throw error;
      setIsSetupOpen(false);
      fetchHomeSystem(selectedHome);
    } catch (error: any) {
      console.error('Error saving home systems:', error);
      setError('Failed to save home systems. Please try again.');
    } finally {
      setSubmitting(false);
    }
  };

  const getPriorityColor = (priority: string) => {
    switch (priority.toLowerCase()) {
      case 'high': return 'text-red-600 bg-red-50';
      case 'normal': return 'text-blue-600 bg-blue-50';
      case 'low': return 'text-green-600 bg-green-50';
      default: return 'text-gray-600 bg-gray-50';
    }
  };

  const formatDuration = (minutes: number) => {
    if (minutes < 60) {
      return `${minutes} min`;
    }
    const hours = Math.floor(minutes / 60);
    const remainingMinutes = minutes % 60;
    return remainingMinutes > 0 
      ? `${hours} hr ${remainingMinutes} min`
      : `${hours} hr`;
  };

  const renderSetupStep = () => {
    const steps = [
      { type: 'heating_systems', title: 'Heating', description: 'How is your home heated?' },
      { type: 'cooling_systems', title: 'Cooling', description: 'How is your home cooled?' },
      { type: 'appliances', title: 'Appliances', description: 'Which major appliances do you have?' },
      { type: 'other_systems', title: 'Other Systems', description: 'Do you have any of these systems?' },
      { type: 'upgrades', title: 'Upgrades', description: 'Have you installed any of these upgrades?' },
      { type: 'outdoor_features', title: 'Outdoor Features', description: 'What features do you have outside?' }
    ];

    const currentStepData = steps[currentStep];
    const options = systemOptions[currentStepData.type as keyof typeof systemOptions];
    const selectedItems = systemData[currentStepData.type as keyof typeof systemOptions] || [];

    return (
      <div className="space-y-6">
        <div className="space-y-2">
          <h3 className="text-xl font-semibold">{currentStepData.title}</h3>
          <p className="text-gray-600">{currentStepData.description}</p>
        </div>

        <div className="grid grid-cols-2 md:grid-cols-3 gap-3">
          {options.map(option => (
            <button
              key={option.id}
              onClick={() => handleSystemToggle(currentStepData.type as keyof typeof systemOptions, option.id)}
              className={`p-4 rounded-lg text-left transition-colors ${
                selectedItems.includes(option.id)
                  ? 'bg-black text-white'
                  : 'bg-gray-50 hover:bg-gray-100'
              }`}
            >
              <span className="block font-medium">{option.label}</span>
            </button>
          ))}
        </div>
      </div>
    );
  };

  const renderMaintenanceTasks = () => {
    if (tasksLoading) {
      return (
        <div className="flex justify-center py-8">
          <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
        </div>
      );
    }

    if (maintenanceTasks.length === 0) {
      return (
        <div className="text-center py-8">
          <div className="space-y-3">
            <AlertTriangle className="w-8 h-8 mx-auto text-gray-400" />
            <div>
              <p className="font-medium">No Maintenance Tasks</p>
              <p className="text-gray-600">Configure your home systems to see recommended maintenance tasks</p>
            </div>
            <button
              onClick={() => setIsSetupOpen(true)}
              className="px-4 py-2 text-sm border border-black text-black hover:bg-black hover:text-white transition-colors rounded-md"
            >
              Configure Systems
            </button>
          </div>
        </div>
      );
    }

    const groupedTasks = maintenanceTasks.reduce((acc, task) => {
      const key = task.system_type;
      if (!acc[key]) {
        acc[key] = [];
      }
      acc[key].push(task);
      return acc;
    }, {} as Record<string, MaintenanceTask[]>);

    return (
      <div className="space-y-6">
        {Object.entries(groupedTasks).map(([type, tasks]) => (
          <div key={type} className="space-y-4">
            <h3 className="text-lg font-semibold capitalize">
              {type === 'hvac' ? 'HVAC' : type} Systems
            </h3>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              {tasks.map(task => (
                <div
                  key={task.id}
                  className="p-4 bg-gray-50 rounded-lg space-y-3"
                >
                  <div className="flex items-start justify-between">
                    <div>
                      <h4 className="font-medium">{task.task_name}</h4>
                      <p className="text-sm text-gray-600">{task.description}</p>
                    </div>
                    <span className={`px-2 py-1 text-sm rounded-md ${getPriorityColor(task.priority)}`}>
                      {task.priority}
                    </span>
                  </div>
                  <div className="flex items-center space-x-4 text-sm text-gray-600">
                    <div className="flex items-center space-x-1">
                      <Clock className="w-4 h-4" />
                      <span>{formatDuration(task.estimated_duration_minutes)}</span>
                    </div>
                    <div className="flex items-center space-x-1">
                      <Calendar className="w-4 h-4" />
                      <span>Every {task.frequency_months} month{task.frequency_months !== 1 ? 's' : ''}</span>
                    </div>
                    {task.professional_required && (
                      <div className="flex items-center space-x-1">
                        <Wrench className="w-4 h-4" />
                        <span>Professional required</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
      </div>
    );
  }

  if (homes.length === 0) {
    return (
      <div className="space-y-8">
        <header>
          <h1 className="text-3xl font-bold">Maintenance Plan</h1>
          <p className="text-gray-600">Keep your home in perfect condition</p>
        </header>

        <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-8">
          <div className="text-center space-y-4">
            <AlertTriangle className="w-8 h-8 mx-auto text-gray-400" />
            <div>
              <p className="font-medium">No Homes Found</p>
              <p className="text-gray-600">Add a home to create a maintenance plan</p>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <header className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Maintenance Plan</h1>
          <p className="text-gray-600">Keep your home in perfect condition</p>
        </div>
        <button
          onClick={() => setIsSetupOpen(true)}
          className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
        >
          <Settings className="w-4 h-4" />
          <span>Configure Systems</span>
        </button>
      </header>

      <div className="bg-white rounded-lg shadow-sm border border-gray-100">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-3">
              <Calendar className="w-6 h-6" />
              <h2 className="text-xl font-semibold">Maintenance Tasks</h2>
            </div>
            <select
              value={selectedHome}
              onChange={(e) => handleHomeChange(e.target.value)}
              className="px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
            >
              {homes.map((home) => (
                <option key={home.id} value={home.id}>
                  {home.address}, {home.city}
                </option>
              ))}
            </select>
          </div>

          {renderMaintenanceTasks()}
        </div>
      </div>

      {isSetupOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-2xl w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Configure Home Systems</h2>
              <button
                onClick={() => setIsSetupOpen(false)}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <div className="mb-8">
              <div className="flex items-center justify-center space-x-2 mb-2">
                {Object.keys(systemOptions).map((_, index) => (
                  <div
                    key={index}
                    className={`h-2 w-16 rounded-full transition-colors ${
                      index <= currentStep ? 'bg-black' : 'bg-gray-200'
                    }`}
                  />
                ))}
              </div>
            </div>

            {renderSetupStep()}

            {error && (
              <p className="text-red-500 text-sm mt-4">{error}</p>
            )}

            <div className="flex justify-between mt-8">
              <button
                onClick={() => setCurrentStep(prev => prev - 1)}
                disabled={currentStep === 0}
                className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800 disabled:opacity-50"
              >
                Back
              </button>
              <button
                onClick={() => {
                  if (currentStep === Object.keys(systemOptions).length - 1) {
                    handleSubmit();
                  } else {
                    setCurrentStep(prev => prev + 1);
                  }
                }}
                disabled={submitting}
                className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors disabled:opacity-50"
              >
                {submitting ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    <span>Saving...</span>
                  </>
                ) : currentStep === Object.keys(systemOptions).length - 1 ? (
                  <>
                    <span>Finish</span>
                    <CheckCircle2 className="w-4 h-4" />
                  </>
                ) : (
                  <>
                    <span>Next</span>
                    <ArrowRight className="w-4 h-4" />
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MaintenancePlan;