import React, { useState } from 'react';
import { MapPin, X, Plus, Loader2, Trash2, Settings } from 'lucide-react';
import { supabase } from '../../lib/supabase';
import { useNavigate } from 'react-router-dom';

interface Home {
  id: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  square_footage: number | null;
  year_built: number | null;
}

interface HomesListProps {
  homes: Home[];
  onClose: () => void;
  onAddHome: () => void;
  onHomeUpdated: () => void;
  isOpen: boolean;
}

const HomesList: React.FC<HomesListProps> = ({ homes, onClose, onAddHome, onHomeUpdated, isOpen }) => {
  const navigate = useNavigate();
  const [deleting, setDeleting] = useState(false);
  const [error, setError] = useState('');

  if (!isOpen) return null;

  const handleDelete = async (homeId: string) => {
    if (!confirm('Are you sure you want to delete this home? This action cannot be undone.')) {
      return;
    }

    setDeleting(true);
    setError('');

    try {
      const { error } = await supabase
        .from('homes')
        .delete()
        .eq('id', homeId);

      if (error) throw error;
      onHomeUpdated();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setDeleting(false);
    }
  };

  const handleConfigureSystems = (homeId: string) => {
    onClose(); // Close the homes list modal
    navigate('/maintenance-plan'); // Navigate to maintenance plan page where systems can be configured
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-xl max-w-3xl w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <div>
            <h2 className="text-xl font-semibold">Your Homes</h2>
            <p className="text-sm text-gray-600 mt-1">
              Manage all your properties in one place
            </p>
          </div>
          <div className="flex items-center space-x-4">
            <button
              onClick={onAddHome}
              className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
              <Plus className="w-4 h-4" />
              <span>Add Home</span>
            </button>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        {error && (
          <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-lg text-sm">
            {error}
          </div>
        )}

        <div className="space-y-4 max-h-[60vh] overflow-y-auto">
          {homes.length === 0 ? (
            <div className="text-center py-12">
              <MapPin className="w-12 h-12 text-gray-400 mx-auto mb-4" />
              <h3 className="text-lg font-medium mb-2">No Homes Added</h3>
              <p className="text-gray-600 mb-4">
                Add your first home to start managing your property
              </p>
              <button
                onClick={onAddHome}
                className="inline-flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
              >
                <Plus className="w-4 h-4" />
                <span>Add Your First Home</span>
              </button>
            </div>
          ) : (
            homes.map((home) => (
              <div
                key={home.id}
                className="p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
              >
                <div className="flex items-start justify-between">
                  <div className="flex items-start space-x-3">
                    <div className="mt-1">
                      <MapPin className="w-5 h-5 text-gray-400" />
                    </div>
                    <div className="space-y-2">
                      <div>
                        <h3 className="font-medium">{home.address}</h3>
                        <p className="text-sm text-gray-600">
                          {home.city}, {home.state} {home.zip_code}
                        </p>
                      </div>
                      {(home.square_footage || home.year_built) && (
                        <div className="flex items-center space-x-3 text-sm text-gray-500">
                          {home.square_footage && (
                            <span>{home.square_footage.toLocaleString()} sq ft</span>
                          )}
                          {home.year_built && (
                            <span>Built {home.year_built}</span>
                          )}
                        </div>
                      )}
                      <button
                        onClick={() => handleConfigureSystems(home.id)}
                        className="inline-flex items-center space-x-2 px-3 py-1.5 text-blue-600 hover:bg-blue-50 rounded-lg transition-colors text-sm font-medium"
                      >
                        <Settings className="w-4 h-4" />
                        <span>Configure Systems</span>
                      </button>
                    </div>
                  </div>
                  <div className="flex items-center space-x-2">
                    <button
                      onClick={() => handleDelete(home.id)}
                      disabled={deleting}
                      className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
                      title="Delete home"
                    >
                      {deleting ? (
                        <Loader2 className="w-5 h-5 animate-spin" />
                      ) : (
                        <Trash2 className="w-5 h-5" />
                      )}
                    </button>
                  </div>
                </div>
              </div>
            ))
          )}
        </div>
      </div>
    </div>
  );
};

export default HomesList;