import React, { useState, useEffect } from 'react';
import { Table as Tabs, Settings, Users, Bug, Loader2, Mail, Phone, Calendar, Home as HomeIcon, MessageSquare } from 'lucide-react';
import { createTestUser, supabase } from '../../lib/supabase';
import ServiceRequestsTab from './ServiceRequestsTab';

interface Profile {
  id: string;
  full_name: string;
  phone_number: string;
  created_at: string;
  plan: string;
  is_system_manager: boolean;
  home_manager: string;
  home_manager_assigned_at: string;
  home_manager_assigned_by: string;
}

const homeManagers = ['Brad', 'Brandon', 'Bryce', 'Unassigned'] as const;

const Admin = () => {
  const [activeTab, setActiveTab] = useState<'overview' | 'users' | 'requests' | 'debug'>('overview');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<string | null>(null);
  const [users, setUsers] = useState<Profile[]>([]);
  const [updatingUserId, setUpdatingUserId] = useState<string | null>(null);
  const [testUserData, setTestUserData] = useState({
    email: '',
    password: '',
    fullName: '',
    phoneNumber: ''
  });

  useEffect(() => {
    if (activeTab === 'users') {
      fetchUsers();
    }
  }, [activeTab]);

  const fetchUsers = async () => {
    try {
      setLoading(true);
      
      const { data: profiles, error: profilesError } = await supabase
        .from('profiles')
        .select(`
          id,
          full_name,
          phone_number,
          created_at,
          plan,
          is_system_manager,
          home_manager,
          home_manager_assigned_at,
          home_manager_assigned_by
        `)
        .order('created_at', { ascending: false });

      if (profilesError) throw profilesError;

      setUsers(profiles || []);
    } catch (error: any) {
      console.error('Error fetching users:', error);
      setError('Failed to fetch users');
    } finally {
      setLoading(false);
    }
  };

  const handleHomeManagerChange = async (userId: string, newManager: string) => {
    try {
      setUpdatingUserId(userId);
      setError(null);

      // Get the current user's session to ensure they're a system manager
      const { data: { user } } = await supabase.auth.getUser();
      if (!user) throw new Error('Not authenticated');

      // Verify the current user is a system manager
      const { data: currentProfile } = await supabase
        .from('profiles')
        .select('is_system_manager')
        .eq('id', user.id)
        .single();

      if (!currentProfile?.is_system_manager) {
        throw new Error('Only system managers can update home managers');
      }

      // Update the profile with the new home manager
      const { error: updateError } = await supabase
        .from('profiles')
        .update({ 
          home_manager: newManager,
          home_manager_assigned_at: new Date().toISOString(),
          home_manager_assigned_by: user.id
        })
        .eq('id', userId);

      if (updateError) throw updateError;
      
      // Update the local state to reflect the change
      setUsers(prevUsers => 
        prevUsers.map(u => 
          u.id === userId 
            ? {
                ...u,
                home_manager: newManager,
                home_manager_assigned_at: new Date().toISOString(),
                home_manager_assigned_by: user.id
              }
            : u
        )
      );
      
      // Show success message
      setSuccess(`Home manager updated successfully`);
      setTimeout(() => setSuccess(null), 3000);
    } catch (error: any) {
      console.error('Error updating home manager:', error);
      setError(`Failed to update home manager: ${error.message}`);
    } finally {
      setUpdatingUserId(null);
    }
  };

  const handleCreateTestUser = async (e: React.FormEvent) => {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setSuccess(null);

    try {
      const user = await createTestUser(testUserData);
      setSuccess(`Test user created successfully! User ID: ${user.id}`);
      setTestUserData({
        email: '',
        password: '',
        fullName: '',
        phoneNumber: ''
      });
      
      if (activeTab === 'users') {
        fetchUsers();
      }
    } catch (error: any) {
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const renderUsersTable = () => {
    if (loading) {
      return (
        <div className="flex justify-center py-8">
          <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
        </div>
      );
    }

    if (users.length === 0) {
      return (
        <div className="text-center py-8">
          <Users className="w-12 h-12 text-gray-400 mx-auto mb-4" />
          <h3 className="text-lg font-medium mb-2">No Users Found</h3>
          <p className="text-gray-600">There are no users in the system yet.</p>
        </div>
      );
    }

    return (
      <div className="overflow-x-auto">
        <table className="w-full">
          <thead>
            <tr className="border-b border-gray-200">
              <th className="text-left py-3 px-4">Name</th>
              <th className="text-left py-3 px-4">Phone</th>
              <th className="text-left py-3 px-4">Plan</th>
              <th className="text-left py-3 px-4">Role</th>
              <th className="text-left py-3 px-4">Home Manager</th>
              <th className="text-left py-3 px-4">Joined</th>
            </tr>
          </thead>
          <tbody className="divide-y divide-gray-100">
            {users.map((user) => (
              <tr key={user.id} className="hover:bg-gray-50">
                <td className="py-3 px-4">
                  <div className="font-medium">{user.full_name || 'N/A'}</div>
                </td>
                <td className="py-3 px-4">
                  <div className="flex items-center space-x-2">
                    <Phone className="w-4 h-4 text-gray-400" />
                    <span>{user.phone_number || 'N/A'}</span>
                  </div>
                </td>
                <td className="py-3 px-4">
                  <span className={`inline-flex items-center px-2 py-1 rounded-md text-sm ${
                    user.plan === 'managed' 
                      ? 'bg-black text-white' 
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {user.plan === 'managed' ? 'Premium' : 'Basic'}
                  </span>
                </td>
                <td className="py-3 px-4">
                  <span className={`inline-flex items-center px-2 py-1 rounded-md text-sm ${
                    user.is_system_manager
                      ? 'bg-blue-50 text-blue-700'
                      : 'bg-gray-100 text-gray-800'
                  }`}>
                    {user.is_system_manager ? 'System Manager' : 'User'}
                  </span>
                </td>
                <td className="py-3 px-4">
                  <select
                    value={user.home_manager || 'Unassigned'}
                    onChange={(e) => handleHomeManagerChange(user.id, e.target.value)}
                    disabled={updatingUserId === user.id}
                    className="px-2 py-1 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black text-sm"
                  >
                    {homeManagers.map((manager) => (
                      <option key={manager} value={manager}>
                        {manager}
                      </option>
                    ))}
                  </select>
                  {user.home_manager_assigned_at && (
                    <div className="text-xs text-gray-500 mt-1">
                      Assigned {new Date(user.home_manager_assigned_at).toLocaleDateString()}
                    </div>
                  )}
                </td>
                <td className="py-3 px-4">
                  <div className="flex items-center space-x-2 text-gray-500">
                    <Calendar className="w-4 h-4" />
                    <span>{new Date(user.created_at).toLocaleDateString()}</span>
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  };

  const renderDebugTools = () => (
    <div className="space-y-6">
      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-xl font-semibold mb-4">Create Test User</h2>
        <form onSubmit={handleCreateTestUser} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Email
            </label>
            <input
              type="email"
              value={testUserData.email}
              onChange={(e) => setTestUserData({ ...testUserData, email: e.target.value })}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="test@example.com"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Password
            </label>
            <input
              type="password"
              value={testUserData.password}
              onChange={(e) => setTestUserData({ ...testUserData, password: e.target.value })}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="Enter password"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Full Name
            </label>
            <input
              type="text"
              value={testUserData.fullName}
              onChange={(e) => setTestUserData({ ...testUserData, fullName: e.target.value })}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="John Doe"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Phone Number
            </label>
            <input
              type="tel"
              value={testUserData.phoneNumber}
              onChange={(e) => setTestUserData({ ...testUserData, phoneNumber: e.target.value })}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-md"
              placeholder="(555) 123-4567"
            />
          </div>

          {error && (
            <div className="p-3 bg-red-50 text-red-700 rounded-md text-sm">
              {error}
            </div>
          )}

          {success && (
            <div className="p-3 bg-green-50 text-green-700 rounded-md text-sm">
              {success}
            </div>
          )}

          <button
            type="submit"
            disabled={loading}
            className="w-full px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors disabled:opacity-50"
          >
            {loading ? 'Creating...' : 'Create Test User'}
          </button>
        </form>
      </div>
    </div>
  );

  return (
    <div className="space-y-6">
      <header>
        <h1 className="text-3xl font-bold">Admin Portal</h1>
        <p className="text-gray-600">System management and debugging tools</p>
      </header>

      <nav className="border-b border-gray-200">
        <div className="flex space-x-4">
          <button
            onClick={() => setActiveTab('overview')}
            className={`px-4 py-2 border-b-2 ${
              activeTab === 'overview'
                ? 'border-black text-black'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
            <div className="flex items-center space-x-2">
              <Settings className="w-4 h-4" />
              <span>Overview</span>
            </div>
          </button>
          <button
            onClick={() => setActiveTab('users')}
            className={`px-4 py-2 border-b-2 ${
              activeTab === 'users'
                ? 'border-black text-black'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
            <div className="flex items-center space-x-2">
              <Users className="w-4 h-4" />
              <span>Users</span>
            </div>
          </button>
          <button
            onClick={() => setActiveTab('requests')}
            className={`px-4 py-2 border-b-2 ${
              activeTab === 'requests'
                ? 'border-black text-black'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
            <div className="flex items-center space-x-2">
              <MessageSquare className="w-4 h-4" />
              <span>Service Requests</span>
            </div>
          </button>
          <button
            onClick={() => setActiveTab('debug')}
            className={`px-4 py-2 border-b-2 ${
              activeTab === 'debug'
                ? 'border-black text-black'
                : 'border-transparent text-gray-500 hover:text-gray-700'
            }`}
          >
            <div className="flex items-center space-x-2">
              <Bug className="w-4 h-4" />
              <span>Debug Tools</span>
            </div>
          </button>
        </div>
      </nav>

      {activeTab === 'debug' && renderDebugTools()}
      {activeTab === 'overview' && <div>Overview content coming soon</div>}
      {activeTab === 'users' && (
        <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-3">
              <Users className="w-6 h-6 text-gray-400" />
              <h2 className="text-xl font-semibold">System Users</h2>
            </div>
            <button
              onClick={() => fetchUsers()}
              disabled={loading}
              className="flex items-center space-x-2 px-3 py-1 text-gray-600 hover:text-gray-800 disabled:opacity-50"
            >
              {loading ? (
                <Loader2 className="w-4 h-4 animate-spin" />
              ) : (
                <span>Refresh</span>
              )}
            </button>
          </div>
          {error && (
            <div className="mb-4 p-3 bg-red-50 text-red-600 rounded-lg text-sm">
              {error}
            </div>
          )}
          {success && (
            <div className="mb-4 p-3 bg-green-50 text-green-600 rounded-lg text-sm">
              {success}
            </div>
          )}
          {renderUsersTable()}
        </div>
      )}
      {activeTab === 'requests' && <ServiceRequestsTab />}
    </div>
  );
};

export default Admin;