import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { Home, User, Settings, Package, Bot } from 'lucide-react';
import { supabase } from './lib/supabase';
import Navbar from './components/Navbar';
import Dashboard from './pages/Dashboard';
import ServiceRequests from './pages/ServiceRequests';
import MaintenancePlan from './pages/MaintenancePlan';
import Expenses from './pages/Expenses';
import Services from './pages/Services';
import Profile from './pages/Profile';
import Onboarding from './pages/Onboarding';
import Plans from './pages/Plans';
import Login from './pages/Login';
import Marketing from './pages/Marketing';
import Admin from './pages/admin';
import PrivacyPolicy from './pages/PrivacyPolicy';
import Inventory from './pages/Inventory';
import Assistant from './pages/Assistant';

function App() {
  const [session, setSession] = useState<any>(null);
  const [loading, setLoading] = useState(true);
  const [isSystemManager, setIsSystemManager] = useState(false);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    // Get initial session
    supabase.auth.getSession().then(({ data: { session: initialSession } }) => {
      setSession(initialSession);
      if (initialSession?.user) {
        checkSystemManagerStatus(initialSession.user.id);
      }
      setLoading(false);
    });

    // Listen for auth changes
    const { data: { subscription } } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session?.user) {
        checkSystemManagerStatus(session.user.id);
      } else {
        setIsSystemManager(false);
      }
      setLoading(false);
    });

    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const checkSystemManagerStatus = async (userId: string) => {
    try {
      const { data: profile, error: profileError } = await supabase
        .from('profiles')
        .select('is_system_manager')
        .eq('id', userId)
        .single();

      if (profileError) throw profileError;
      
      setIsSystemManager(profile?.is_system_manager || false);
      
    } catch (error: any) {
      console.error("Profile fetch error:", error);
      setError(error.message);
      setIsSystemManager(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="space-y-4 text-center">
          <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-black"></div>
          {error && (
            <p className="text-red-500 text-sm">{error}</p>
          )}
        </div>
      </div>
    );
  }

  const navItems = [
    { icon: Home, label: 'Dashboard', path: '/dashboard' },
    { icon: Bot, label: 'Assistant', path: '/assistant' },
    { icon: User, label: 'Profile', path: '/profile' },
    ...(isSystemManager ? [{ icon: Settings, label: 'Admin', path: '/admin' }] : [])
  ];

  // Separate protected route component for better organization
  const ProtectedRoute = ({ children }: { children: React.ReactNode }) => {
    if (!session) {
      return <Navigate to="/login" replace />;
    }
    return <>{children}</>;
  };

  // Separate admin route component
  const AdminRoute = () => {
    if (!session) {
      return <Navigate to="/login" replace />;
    }
    
    if (!isSystemManager) {
      return <Navigate to="/dashboard" replace />;
    }

    return (
      <div className="min-h-screen bg-gray-50">
        <Navbar items={navItems} />
        <main className="container mx-auto px-4 py-8">
          <Admin />
        </main>
      </div>
    );
  };

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Marketing />} />
        <Route path="/privacy" element={<PrivacyPolicy />} />
        <Route
          path="/login"
          element={session ? <Navigate to="/dashboard" replace /> : <Login />}
        />
        <Route
          path="/onboarding"
          element={session ? <Navigate to="/plans" replace /> : <Onboarding />}
        />
        <Route
          path="/plans"
          element={
            <ProtectedRoute>
              <Plans />
            </ProtectedRoute>
          }
        />
        <Route path="/admin" element={<AdminRoute />} />
        <Route
          path="*"
          element={
            <ProtectedRoute>
              <div className="min-h-screen bg-gray-50">
                <Navbar items={navItems} />
                <main className="container mx-auto px-4 py-8">
                  <Routes>
                    <Route path="/dashboard" element={<Dashboard />} />
                    <Route path="/inventory" element={<Inventory />} />
                    <Route path="/service-requests" element={<ServiceRequests />} />
                    <Route path="/maintenance-plan" element={<MaintenancePlan />} />
                    <Route path="/expenses" element={<Expenses />} />
                    <Route path="/services" element={<Services />} />
                    <Route path="/assistant" element={<Assistant />} />
                    <Route path="/profile" element={<Profile session={session} />} />
                    <Route path="*" element={<Navigate to="/dashboard" replace />} />
                  </Routes>
                </main>
              </div>
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}

export default App;