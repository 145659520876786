import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Package, ArrowRight, Loader2 } from 'lucide-react';
import { supabase } from '../../lib/supabase';

interface InventoryStats {
  total: number;
  byCategory: Record<string, number>;
  recentItems: Array<{
    id: string;
    name: string;
    category: string;
    created_at: string;
  }>;
}

const InventoryOverview = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [stats, setStats] = useState<InventoryStats>({
    total: 0,
    byCategory: {},
    recentItems: []
  });

  useEffect(() => {
    fetchInventoryStats();
  }, []);

  const fetchInventoryStats = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('No authenticated user');
      }

      // Get user's homes
      const { data: homes } = await supabase
        .from('homes')
        .select('id')
        .eq('owner_id', user.id);

      if (!homes || homes.length === 0) {
        setStats({
          total: 0,
          byCategory: {},
          recentItems: []
        });
        return;
      }

      const homeIds = homes.map(h => h.id);

      // Get all inventory items for user's homes
      const { data: items } = await supabase
        .from('inventory_items')
        .select('*')
        .in('home_id', homeIds)
        .order('created_at', { ascending: false });

      if (!items) {
        throw new Error('Failed to fetch inventory items');
      }

      // Calculate stats
      const byCategory = items.reduce((acc, item) => {
        acc[item.category] = (acc[item.category] || 0) + 1;
        return acc;
      }, {} as Record<string, number>);

      setStats({
        total: items.length,
        byCategory,
        recentItems: items.slice(0, 3)
      });
    } catch (error) {
      console.error('Error fetching inventory stats:', error);
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="bg-white rounded-xl shadow-sm border border-gray-100">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-3">
              <Package className="w-6 h-6" />
              <h2 className="text-xl font-semibold">Inventory</h2>
            </div>
          </div>
          <div className="flex justify-center py-4">
            <Loader2 className="w-6 h-6 animate-spin text-gray-400" />
          </div>
        </div>
      </div>
    );
  }

  if (stats.total === 0) {
    return (
      <div className="bg-white rounded-xl shadow-sm border border-gray-100">
        <div className="p-6">
          <div className="flex items-center justify-between mb-6">
            <div className="flex items-center space-x-3">
              <Package className="w-6 h-6" />
              <h2 className="text-xl font-semibold">Inventory</h2>
            </div>
          </div>
          <div className="text-center py-4">
            <p className="text-gray-600 mb-4">Start tracking your home inventory</p>
            <button
              onClick={() => navigate('/inventory')}
              className="inline-flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
              <span>Add Items</span>
              <ArrowRight className="w-4 h-4" />
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-3">
            <Package className="w-6 h-6" />
            <h2 className="text-xl font-semibold">Inventory</h2>
          </div>
          <button 
            onClick={() => navigate('/inventory')}
            className="text-sm text-black hover:text-gray-600 flex items-center space-x-1"
          >
            <span>View all</span>
            <ArrowRight className="w-4 h-4" />
          </button>
        </div>

        <div className="space-y-6">
          <div className="grid grid-cols-2 gap-4">
            <div className="bg-gray-50 rounded-lg p-4">
              <p className="text-sm text-gray-600">Total Items</p>
              <p className="text-2xl font-bold">{stats.total}</p>
            </div>
            <div className="bg-gray-50 rounded-lg p-4">
              <p className="text-sm text-gray-600">Categories</p>
              <p className="text-2xl font-bold">{Object.keys(stats.byCategory).length}</p>
            </div>
          </div>

          {stats.recentItems.length > 0 && (
            <div>
              <h3 className="text-sm font-medium text-gray-700 mb-3">Recently Added</h3>
              <div className="space-y-3">
                {stats.recentItems.map((item) => (
                  <div
                    key={item.id}
                    className="flex items-center justify-between text-sm"
                  >
                    <div>
                      <p className="font-medium">{item.name}</p>
                      <p className="text-gray-600">{item.category}</p>
                    </div>
                    <span className="text-gray-500">
                      {new Date(item.created_at).toLocaleDateString()}
                    </span>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default InventoryOverview;