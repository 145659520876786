import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Wrench, ClipboardList, DollarSign, ListChecks, Building } from 'lucide-react';

interface QuickActionsProps {
  onManageHomes: () => void;
}

const QuickActions: React.FC<QuickActionsProps> = ({ onManageHomes }) => {
  const navigate = useNavigate();

  const quickActions = [
    {
      icon: Wrench,
      label: 'Submit Request',
      description: 'Create a new service request',
      path: '/service-requests',
      color: 'bg-blue-50'
    },
    {
      icon: ClipboardList,
      label: 'View Requests',
      description: 'Check existing service requests',
      path: '/service-requests',
      color: 'bg-indigo-50'
    },
    {
      icon: ListChecks,
      label: 'Maintenance Plan',
      description: 'View upcoming maintenance tasks',
      path: '/maintenance-plan',
      color: 'bg-purple-50'
    },
      {
      icon: DollarSign,
      label: 'Track Expenses',
      description: 'View and manage spending',
      path: '/expenses',
      color: 'bg-green-50'
    },
    {
      icon: Building,
      label: 'Manage Homes',
      description: 'View and edit your properties',
      onClick: onManageHomes,
      color: 'bg-orange-50'
    }
  ];

  return (
    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-5 gap-4">
      {quickActions.map((action, index) => {
        const Icon = action.icon;
        return (
          <button
            key={index}
            onClick={() => action.onClick ? action.onClick() : navigate(action.path)}
            className="p-4 bg-white rounded-xl shadow-sm border border-gray-100 hover:border-gray-200 transition-all hover:shadow-md text-left"
          >
            <div className={`w-10 h-10 ${action.color} rounded-lg flex items-center justify-center mb-3`}>
              <Icon className="w-5 h-5 text-gray-700" />
            </div>
            <h3 className="font-medium mb-1">{action.label}</h3>
            <p className="text-sm text-gray-600">{action.description}</p>
          </button>
        );
      })}
    </div>
  );
};

export default QuickActions;