import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Clock, ArrowRight } from 'lucide-react';

interface Task {
  id: string;
  name: string;
  due_date: string;
  priority: string;
  status: string;
}

interface UpcomingTasksProps {
  tasks: Task[];
}

const UpcomingTasks: React.FC<UpcomingTasksProps> = ({ tasks }) => {
  const navigate = useNavigate();

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-6">
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold">Upcoming Tasks</h2>
          <button 
            onClick={() => navigate('/maintenance-plan')}
            className="text-sm text-black hover:text-gray-600 flex items-center space-x-1"
          >
            <span>View all</span>
            <ArrowRight className="w-4 h-4" />
          </button>
        </div>
        <div className="space-y-4">
          {tasks.map((task, index) => (
            <div key={index} className="flex items-start space-x-3">
              <div className="w-8 h-8 bg-gray-50 rounded-lg flex items-center justify-center mt-1">
                <Clock className="w-4 h-4 text-gray-600" />
              </div>
              <div>
                <p className="font-medium">{task.name}</p>
                <p className="text-sm text-gray-600">
                  Due {new Date(task.due_date).toLocaleDateString()}
                </p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default UpcomingTasks;