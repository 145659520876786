import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import * as Sentry from "@sentry/react";
import App from './App.tsx';
import './index.css';

// Initialize Sentry
Sentry.init({
  dsn: "https://2910d4ed76e02c06fd5a26eaa1dba1f6@o4508876865273856.ingest.us.sentry.io/4508876867174400",
  integrations: [
    new Sentry.BrowserTracing({
      // Set sampling rate for performance monitoring
      tracePropagationTargets: ["localhost", "honeydone.com"],
    }),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: 1.0, // Capture 100% of transactions in development
  // Session Replay
  replaysSessionSampleRate: 0.1, // Sample rate for session replays
  replaysOnErrorSampleRate: 1.0, // Sample rate for replays on error
  environment: import.meta.env.MODE
});

createRoot(document.getElementById('root')!).render(
  <StrictMode>
    <App />
  </StrictMode>
);