import React from 'react';
import { Bot, User } from 'lucide-react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import ChatAction from './ChatAction';

interface ChatMessageProps {
  role: 'user' | 'assistant';
  content: string;
  avatarUrl?: string;
  onAvatarError?: () => void;
  actions?: Array<{
    type: 'viewInventory' | 'viewInventoryItem' | 'viewMaintenancePlan' | 'createServiceRequest';
    data?: {
      itemId?: string;
      itemName?: string;
    };
  }>;
}

const ChatMessage: React.FC<ChatMessageProps> = ({
  role,
  content,
  avatarUrl,
  onAvatarError,
  actions
}) => {
  const isAssistant = role === 'assistant';

  return (
    <div
      className={`flex items-start space-x-3 ${
        isAssistant ? '' : 'flex-row-reverse space-x-reverse'
      }`}
    >
      <div className={`w-8 h-8 rounded-full flex items-center justify-center overflow-hidden ${
        isAssistant ? 'bg-black' : 'bg-blue-600'
      }`}>
        {isAssistant ? (
          avatarUrl ? (
            <img
              src={avatarUrl}
              alt="HoneyBot"
              className="w-full h-full object-cover"
              onError={onAvatarError}
            />
          ) : (
            <Bot className="w-5 h-5 text-white" />
          )
        ) : (
          <User className="w-5 h-5 text-white" />
        )}
      </div>
      <div className={`flex-1 ${isAssistant ? 'space-y-2' : ''}`}>
        <div className={`rounded-lg p-4 ${
          isAssistant ? 'bg-gray-50' : 'bg-blue-50'
        }`}>
          <div className="prose prose-sm max-w-none">
            <ReactMarkdown 
              remarkPlugins={[remarkGfm]}
              components={{
                // Style headings
                h1: ({node, ...props}) => <h1 className="text-xl font-bold mb-2" {...props} />,
                h2: ({node, ...props}) => <h2 className="text-lg font-bold mb-2" {...props} />,
                h3: ({node, ...props}) => <h3 className="text-base font-bold mb-2" {...props} />,
                
                // Style paragraphs
                p: ({node, ...props}) => <p className="mb-2 last:mb-0" {...props} />,
                
                // Style lists
                ul: ({node, ...props}) => <ul className="list-disc list-inside mb-2" {...props} />,
                ol: ({node, ...props}) => <ol className="list-decimal list-inside mb-2" {...props} />,
                
                // Style emphasis and strong text
                em: ({node, ...props}) => <em className="italic" {...props} />,
                strong: ({node, ...props}) => <strong className="font-bold" {...props} />,
                
                // Style code blocks and inline code
                code: ({node, inline, ...props}) => 
                  inline ? (
                    <code className="bg-gray-100 px-1 py-0.5 rounded text-sm font-mono" {...props} />
                  ) : (
                    <code className="block bg-gray-100 p-2 rounded text-sm font-mono my-2" {...props} />
                  ),
                
                // Style blockquotes
                blockquote: ({node, ...props}) => (
                  <blockquote className="border-l-4 border-gray-300 pl-4 italic my-2" {...props} />
                ),
                
                // Style links
                a: ({node, ...props}) => (
                  <a className="text-blue-600 hover:underline" target="_blank" rel="noopener noreferrer" {...props} />
                )
              }}
            >
              {content}
            </ReactMarkdown>
          </div>
        </div>
        {isAssistant && actions && actions.length > 0 && (
          <div className="flex flex-wrap gap-2">
            {actions.map((action, index) => (
              <ChatAction key={index} {...action} />
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;