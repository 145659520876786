import { createClient } from '@supabase/supabase-js';

const supabaseUrl = import.meta.env.VITE_SUPABASE_URL;
const supabaseAnonKey = import.meta.env.VITE_SUPABASE_ANON_KEY;

if (!supabaseUrl || !supabaseAnonKey) {
  throw new Error('Missing Supabase environment variables');
}

export const supabase = createClient(supabaseUrl, supabaseAnonKey, {
  auth: {
    persistSession: true,
    autoRefreshToken: true,
    detectSessionInUrl: true
  },
  realtime: {
    params: {
      eventsPerSecond: 10
    }
  },
  global: {
    headers: {
      'x-application-name': 'honeydone'
    }
  }
});

export const createHubspotContact = async (contactData: {
  email: string;
  fullName: string;
  phoneNumber: string;
  userId: string;
}) => {
  try {
    console.log(JSON.stringify(contactData));
    const response = await fetch('https://hd-contact-creation-512019994511.us-east4.run.app', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(contactData),
    });

    if (!response.ok) {
      throw new Error(`Failed to create Hubspot contact: ${response.statusText}`);
    }

    return await response.json();
  } catch (error) {
    console.error('Error creating Hubspot contact:', error);
    throw error;
  }
};

export const createTestUser = async (userData: {
  email: string;
  password: string;
  fullName: string;
  phoneNumber: string;
}) => {
  try {
    // Create user in Supabase
    const { data: authData, error: authError } = await supabase.auth.signUp({
      email: userData.email,
      password: userData.password,
    });

    if (authError) throw authError;
    if (!authData.user) throw new Error('No user data returned');

    // Create profile
    const { error: profileError } = await supabase
      .from('profiles')
      .insert({
        id: authData.user.id,
        full_name: userData.fullName,
        phone_number: userData.phoneNumber,
        plan: 'free'
      });

    if (profileError) throw profileError;

    // Create Hubspot contact
    await createHubspotContact({
      email: userData.email,
      fullName: userData.fullName,
      phoneNumber: userData.phoneNumber,
      userId: authData.user.id
    });

    return authData.user;
  } catch (error) {
    console.error('Error creating test user:', error);
    throw error;
  }
};

export const resetPassword = async (email: string) => {
  const { error } = await supabase.auth.resetPasswordForEmail(email, {
    redirectTo: `${window.location.origin}/login?reset=true`,
  });
  
  if (error) throw error;
};

// Helper function to check if a Supabase error is a connection error
export const isSupabaseConnectionError = (error: any) => {
  return error?.message?.includes('Failed to fetch') || 
         error?.message?.includes('NetworkError') ||
         error?.message?.includes('network request failed');
};

// Helper function to handle Supabase errors
export const handleSupabaseError = (error: any) => {
  if (isSupabaseConnectionError(error)) {
    return new Error('Unable to connect to the server. Please check your internet connection and try again.');
  }
  return error;
};