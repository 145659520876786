import React from 'react';
import { Receipt } from 'lucide-react';

interface Expense {
  amount: number;
  date: string;
  category: string;
}

interface RecentActivityProps {
  expenses: Expense[];
}

const RecentActivity: React.FC<RecentActivityProps> = ({ expenses }) => {
  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-6">
        <h2 className="text-xl font-semibold mb-6">Recent Activity</h2>
        <div className="space-y-6">
          {expenses.slice(0, 3).map((expense, index) => (
            <div key={index} className="flex items-center space-x-4">
              <div className="w-10 h-10 bg-gray-50 rounded-full flex items-center justify-center">
                <Receipt className="w-5 h-5 text-gray-600" />
              </div>
              <div className="flex-1">
                <p className="font-medium">{expense.category}</p>
                <p className="text-sm text-gray-600">
                  {new Date(expense.date).toLocaleDateString()}
                </p>
              </div>
              <p className="font-medium">{formatCurrency(expense.amount)}</p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default RecentActivity;