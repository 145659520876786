import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { CheckCircle2, ArrowRight, Loader2, Calendar, Users, PenTool as Tool, Shield, Clock, Home, X } from 'lucide-react';
import { supabase } from '../lib/supabase';

const Plans = () => {
  const navigate = useNavigate();
  const [selectedPlan, setSelectedPlan] = useState<'free' | 'managed'>('free');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [showConfirmation, setShowConfirmation] = useState(false);

  const plans = [
    {
      id: 'free',
      name: 'Self-Managed',
      description: 'Perfect for homeowners who want to manage maintenance themselves',
      price: 'Free',
      features: [
        'Custom maintenance plan',
        'Maintenance tracking and reminders',
        'Service request management',
        'Expense tracking',
        'Unlimited homes',
        'Mobile-friendly dashboard'
      ]
    },
    {
      id: 'managed',
      name: 'Professionally Managed',
      description: 'Let us handle your home maintenance needs',
      price: 'Custom pricing based on your needs',
      priceNote: 'No payment required today',
      features: [
        'All Free features',
        'Dedicated home manager',
        'Professional maintenance service',
        'Regular inspections',
        'Priority support',
        '24/7 emergency assistance'
      ]
    }
  ];

  const handlePlanSelection = async () => {
    setLoading(true);
    setError('');

    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('User not found');
      }

      const { error: updateError } = await supabase
        .from('profiles')
        .update({ 
          plan: selectedPlan,
          plan_updated_at: new Date().toISOString()
        })
        .eq('id', user.id);

      if (updateError) throw updateError;

      if (selectedPlan === 'managed') {
        setShowConfirmation(true);
      } else {
        navigate('/');
      }
    } catch (error: any) {
      console.error('Error saving plan selection:', error);
      setError(error.message);
    } finally {
      setLoading(false);
    }
  };

  const getFeatureIcon = (feature: string) => {
    if (feature.includes('tracking')) return Tool;
    if (feature.includes('service')) return Users;
    if (feature.includes('maintenance')) return Calendar;
    if (feature.includes('emergency')) return Shield;
    if (feature.includes('24/7')) return Clock;
    return Home;
  };

  return (
    <div className="min-h-screen bg-gray-50 py-12">
      <div className="max-w-4xl mx-auto px-4">
        <div className="text-center mb-12">
          <h1 className="text-3xl font-bold mb-4">Choose Your Plan</h1>
          <p className="text-gray-600 max-w-xl mx-auto">
            Select the plan that best fits your needs. You can always upgrade or downgrade later.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-8">
          {plans.map((plan) => {
            const isSelected = selectedPlan === plan.id;
            return (
              <div
                key={plan.id}
                className={`bg-white rounded-xl shadow-sm border-2 transition-all ${
                  isSelected 
                    ? 'border-black ring-2 ring-black ring-opacity-5' 
                    : 'border-gray-100 hover:border-gray-200'
                }`}
              >
                <div className="p-6">
                  <div className="flex items-start justify-between mb-4">
                    <div>
                      <h2 className="text-xl font-semibold">{plan.name}</h2>
                      <p className="text-gray-600 mt-1">{plan.description}</p>
                    </div>
                    <button
                      onClick={() => setSelectedPlan(plan.id as 'free' | 'managed')}
                      className={`w-6 h-6 rounded-full border-2 flex items-center justify-center ${
                        isSelected
                          ? 'bg-black border-black text-white'
                          : 'border-gray-300'
                      }`}
                    >
                      {isSelected && <CheckCircle2 className="w-4 h-4" />}
                    </button>
                  </div>

                  <div className="mb-6">
                    <p className="text-2xl font-bold">{plan.price}</p>
                    {'priceNote' in plan && (
                      <p className="text-sm text-gray-600 mt-1">{plan.priceNote}</p>
                    )}
                  </div>

                  <ul className="space-y-3">
                    {plan.features.map((feature, index) => {
                      const Icon = getFeatureIcon(feature);
                      return (
                        <li key={index} className="flex items-center space-x-3">
                          <div className="w-8 h-8 bg-gray-50 rounded-lg flex items-center justify-center">
                            <Icon className="w-4 h-4 text-gray-600" />
                          </div>
                          <span className="text-gray-600">{feature}</span>
                        </li>
                      );
                    })}
                  </ul>
                </div>

                <div className="border-t border-gray-100 p-6">
                  <button
                    onClick={() => setSelectedPlan(plan.id as 'free' | 'managed')}
                    className={`w-full flex items-center justify-center space-x-2 px-6 py-3 rounded-lg transition-colors ${
                      isSelected
                        ? 'bg-black text-white'
                        : 'bg-gray-50 text-gray-800 hover:bg-gray-100'
                    }`}
                  >
                    <span>{isSelected ? 'Selected' : 'Select'} {plan.name}</span>
                  </button>
                </div>
              </div>
            );
          })}
        </div>

        {error && (
          <p className="text-red-500 text-center mb-6">{error}</p>
        )}

        <div className="flex justify-center">
          <button
            onClick={handlePlanSelection}
            disabled={loading}
            className="flex items-center space-x-2 px-8 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors disabled:opacity-50"
          >
            {loading ? (
              <>
                <Loader2 className="w-5 h-5 animate-spin" />
                <span>Confirming...</span>
              </>
            ) : (
              <>
                <span>Confirm Selection</span>
                <ArrowRight className="w-5 h-5" />
              </>
            )}
          </button>
        </div>
      </div>

      {/* Confirmation Modal */}
      {showConfirmation && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <div className="w-12 h-12 bg-green-50 rounded-full flex items-center justify-center">
                <CheckCircle2 className="w-6 h-6 text-green-600" />
              </div>
              <button
                onClick={() => navigate('/')}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <h2 className="text-2xl font-bold mb-4">Thank You!</h2>
            <div className="space-y-4">
              <p className="text-gray-600">
                We're excited to help you maintain your home! Our team will contact you within 1-2 business days to:
              </p>
              <ul className="space-y-2">
                <li className="flex items-center space-x-2 text-gray-600">
                  <CheckCircle2 className="w-5 h-5 text-green-600" />
                  <span>Discuss your home maintenance needs</span>
                </li>
                <li className="flex items-center space-x-2 text-gray-600">
                  <CheckCircle2 className="w-5 h-5 text-green-600" />
                  <span>Create a customized maintenance plan</span>
                </li>
                <li className="flex items-center space-x-2 text-gray-600">
                  <CheckCircle2 className="w-5 h-5 text-green-600" />
                  <span>Provide pricing based on your requirements</span>
                </li>
              </ul>
              <p className="text-gray-600">
                In the meantime, you can start using all the free features of HunnyDone to manage your home.
              </p>
            </div>

            <button
              onClick={() => navigate('/')}
              className="w-full mt-6 flex items-center justify-center space-x-2 px-6 py-3 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
              <span>Go to Dashboard</span>
              <ArrowRight className="w-5 h-5" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default Plans;