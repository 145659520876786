import React, { useState, useEffect } from 'react';
import { Plus, Loader2 } from 'lucide-react';
import { supabase, handleSupabaseError } from '../lib/supabase';
import QuickStats from './dashboard/QuickStats';
import QuickActions from './dashboard/QuickActions';
import ManagedServices from './dashboard/ManagedServices';
import RecentActivity from './dashboard/RecentActivity';
import UpcomingTasks from './dashboard/UpcomingTasks';
import ImportantReminders from './dashboard/ImportantReminders';
import HomesList from './dashboard/HomesList';
import AddHomeModal from './dashboard/AddHomeModal';
import ScheduleConsultation from './dashboard/ScheduleConsultation';
import InventoryOverview from './dashboard/InventoryOverview';

interface Home {
  id: string;
  address: string;
  city: string;
  state: string;
  zip_code: string;
  square_footage: number | null;
  year_built: number | null;
}

interface Task {
  id: string;
  name: string;
  due_date: string;
  priority: string;
  status: string;
}

interface Expense {
  amount: number;
  date: string;
  category: string;
}

interface ManagedService {
  id: string;
  name: string;
  provider: string;
  frequency: string;
  next_service_date: string;
  status: 'active' | 'paused' | 'cancelled';
}

const Dashboard = () => {
  const [homes, setHomes] = useState<Home[]>([]);
  const [loading, setLoading] = useState(true);
  const [isHomesListOpen, setIsHomesListOpen] = useState(false);
  const [isAddHomeOpen, setIsAddHomeOpen] = useState(false);
  const [expenses, setExpenses] = useState<Expense[]>([]);
  const [tasks, setTasks] = useState<Task[]>([]);
  const [services, setServices] = useState<ManagedService[]>([]);
  const [unconfiguredHomes, setUnconfiguredHomes] = useState<Home[]>([]);

  useEffect(() => {
    fetchHomes();
    fetchExpenses();
    fetchTasks();
    fetchServices();
    checkHomeSystems();
  }, []);

  const fetchHomes = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('No authenticated user');
      }

      const { data, error } = await supabase
        .from('homes')
        .select('*')
        .eq('owner_id', user.id)
        .order('created_at', { ascending: false });

      if (error) throw error;
      setHomes(data || []);
    } catch (error) {
      console.error('Error fetching homes:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchExpenses = async () => {
    try {
      // Get the current user's homes first
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('No authenticated user');
      }

      const { data: homes, error: homesError } = await supabase
        .from('homes')
        .select('id')
        .eq('owner_id', user.id)
        .order('created_at', { ascending: false });

      if (homesError) throw homesError;
      
      if (!homes || homes.length === 0) {
        setExpenses([]);
        return;
      }

      // Then fetch expenses for those homes
      const { data, error } = await supabase
        .from('expenses')
        .select('amount, date, category')
        .in('home_id', homes.map(home => home.id))
        .gte('date', new Date(new Date().getFullYear(), 0, 1).toISOString())
        .order('date', { ascending: false });

      if (error) throw error;
      setExpenses(data || []);
    } catch (error) {
      console.error('Error fetching expenses:', error);
    }
  };

  const fetchTasks = async () => {
    try {
      const { data, error } = await supabase
        .from('maintenance_plans')
        .select('id, task_id, next_due_date, status, maintenance_tasks(name)')
        .order('next_due_date', { ascending: true })
        .limit(5);

      if (error) throw error;
      setTasks(data || []);
    } catch (error) {
      console.error('Error fetching tasks:', error);
    }
  };

  const fetchServices = async () => {
    try {
      const { data, error } = await supabase
        .from('managed_services')
        .select('*')
        .eq('status', 'active')
        .order('next_service_date', { ascending: true })
        .limit(3);

      if (error) throw error;
      setServices(data || []);
    } catch (error) {
      console.error('Error fetching services:', error);
    }
  };

  const checkHomeSystems = async () => {
    try {
      // Get the current user's ID
      const { data: { user } } = await supabase.auth.getUser();
      
      if (!user) {
        throw new Error('No authenticated user');
      }

      // Get homes owned by the current user
      const { data: homes, error: homesError } = await supabase
        .from('homes')
        .select('id, address')
        .eq('owner_id', user.id);

      if (homesError) throw homesError;

      if (!homes || homes.length === 0) {
        setLoading(false);
        return;
      }

      // Get home systems configurations for the user's homes
      const { data: systems, error: systemsError } = await supabase
        .from('home_systems')
        .select('home_id, heating_systems, cooling_systems, appliances, other_systems, upgrades, outdoor_features')
        .in('home_id', homes.map(h => h.id))
        .eq('home_id', homes.map(h => h.id));

      if (systemsError) throw systemsError;

      // Find homes without systems configured
      const unconfigured = homes.filter(home => {
        const homeSystem = systems?.find(s => s.home_id === home.id);
        if (!homeSystem) return true;

        // Check if any system array is empty or null
        const hasConfiguration = Object.values(homeSystem).some(value => 
          Array.isArray(value) && value.length > 0
        );
        return !hasConfiguration;
      });

      setUnconfiguredHomes(unconfigured);
    } catch (error) {
      console.error('Error checking home systems:', handleSupabaseError(error));
    } finally {
      setLoading(false);
    }
  };

  if (loading) {
    return (
      <div className="min-h-[400px] flex items-center justify-center">
        <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
      </div>
    );
  }

  return (
    <div className="space-y-8">
      <header className="flex items-center justify-between">
        <div>
          <h1 className="text-3xl font-bold">Welcome Back</h1>
          <p className="text-gray-600 mt-1">Here's what's happening with your homes</p>
        </div>
      </header>

      {/* Quick Actions */}
      <QuickActions onManageHomes={() => setIsHomesListOpen(true)} />

      {/* Important reminders */}
      <ImportantReminders />
      
      {/* Quick Stats */}
      <QuickStats tasks={tasks} expenses={expenses} />

      {/* Main Content Grid */}
      <div className="grid grid-cols-1 lg:grid-cols-3 gap-8">
        {/* Left Column */}
        <div className="lg:col-span-2 space-y-6">
          {/* Managed Services */}
          <ManagedServices services={services} />

          {/* Recent Activity */}
          <RecentActivity expenses={expenses} />
        </div>

        {/* Right Column */}
        <div className="space-y-6">
          {/* Upcoming Tasks */}
          <UpcomingTasks tasks={tasks} />

          {/* Inventory Overview */}
          <InventoryOverview />
        </div>
      </div>

      {/* Homes List Modal */}
      <HomesList
        homes={homes}
        onClose={() => setIsHomesListOpen(false)}
        onAddHome={() => {
          setIsHomesListOpen(false);
          setIsAddHomeOpen(true);
        }}
        onHomeUpdated={fetchHomes}
        isOpen={isHomesListOpen}
      />

      {/* Add Home Modal */}
      {isAddHomeOpen && (
        <AddHomeModal
          onClose={() => setIsAddHomeOpen(false)}
          onSuccess={() => {
            setIsAddHomeOpen(false);
            fetchHomes();
          }}
        />
      )}
    </div>
  );
};

export default Dashboard;