import React, { useState, useEffect } from 'react';
import { Users, Plus, X, Loader2, CheckCircle2, AlertTriangle, Calendar } from 'lucide-react';
import { supabase } from '../lib/supabase';

interface Service {
  id: string;
  name: string;
  provider: string;
  frequency: string;
  last_service_date: string;
  next_service_date: string;
  notes: string;
  status: 'active' | 'paused' | 'cancelled';
}

const serviceTypes = [
  { id: 'landscaping', label: 'Landscaping' },
  { id: 'housekeeping', label: 'Housekeeping' },
  { id: 'pest_control', label: 'Pest Control' },
  { id: 'pool_maintenance', label: 'Pool Maintenance' },
  { id: 'hvac', label: 'HVAC Service' },
  { id: 'plumbing', label: 'Plumbing' },
  { id: 'electrical', label: 'Electrical' },
  { id: 'roofing', label: 'Roofing' },
  { id: 'cleaning', label: 'Deep Cleaning' }
];

const frequencies = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'biweekly', label: 'Bi-weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
  { value: 'semiannual', label: 'Semi-annual' },
  { value: 'annual', label: 'Annual' },
  { value: 'asneeded', label: 'As Needed' }
];

const Services = () => {
  const [services, setServices] = useState<Service[]>([]);
  const [loading, setLoading] = useState(true);
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [error, setError] = useState('');
  const [formData, setFormData] = useState({
    name: serviceTypes[0].id,
    provider: '',
    frequency: frequencies[0].value,
    last_service_date: '',
    notes: '',
    status: 'active' as const
  });

  useEffect(() => {
    fetchServices();
  }, []);

  const fetchServices = async () => {
    try {
      const { data, error } = await supabase
        .from('managed_services')
        .select('*')
        .order('name', { ascending: true });

      if (error) throw error;
      setServices(data || []);
    } catch (error) {
      console.error('Error fetching services:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError('');

    // Calculate next service date based on frequency and last service date
    const lastServiceDate = new Date(formData.last_service_date);
    let nextServiceDate = new Date(lastServiceDate);
    
    switch (formData.frequency) {
      case 'weekly':
        nextServiceDate.setDate(nextServiceDate.getDate() + 7);
        break;
      case 'biweekly':
        nextServiceDate.setDate(nextServiceDate.getDate() + 14);
        break;
      case 'monthly':
        nextServiceDate.setMonth(nextServiceDate.getMonth() + 1);
        break;
      case 'quarterly':
        nextServiceDate.setMonth(nextServiceDate.getMonth() + 3);
        break;
      case 'semiannual':
        nextServiceDate.setMonth(nextServiceDate.getMonth() + 6);
        break;
      case 'annual':
        nextServiceDate.setFullYear(nextServiceDate.getFullYear() + 1);
        break;
      case 'asneeded':
        nextServiceDate = lastServiceDate;
        break;
    }

    try {
      const { error } = await supabase
        .from('managed_services')
        .insert([{
          ...formData,
          next_service_date: nextServiceDate.toISOString().split('T')[0]
        }]);

      if (error) throw error;

      setFormData({
        name: serviceTypes[0].id,
        provider: '',
        frequency: frequencies[0].value,
        last_service_date: '',
        notes: '',
        status: 'active'
      });
      setIsFormOpen(false);
      fetchServices();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const getStatusColor = (status: Service['status']) => {
    switch (status) {
      case 'active':
        return 'text-green-600 bg-green-50';
      case 'paused':
        return 'text-orange-600 bg-orange-50';
      case 'cancelled':
        return 'text-red-600 bg-red-50';
      default:
        return 'text-gray-600 bg-gray-50';
    }
  };

  const getServiceLabel = (serviceId: string) => {
    return serviceTypes.find(type => type.id === serviceId)?.label || serviceId;
  };

  const getFrequencyLabel = (frequencyValue: string) => {
    return frequencies.find(f => f.value === frequencyValue)?.label || frequencyValue;
  };

  return (
    <div className="space-y-8">
      <header className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Managed Services</h1>
          <p className="text-gray-600">Track and manage your recurring services</p>
        </div>
        <button
          onClick={() => setIsFormOpen(true)}
          className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
        >
          <Plus className="w-4 h-4" />
          <span>Add Service</span>
        </button>
      </header>

      {loading ? (
        <div className="flex justify-center py-12">
          <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
        </div>
      ) : services.length === 0 ? (
        <div className="bg-white rounded-xl shadow-sm border border-gray-100">
          <div className="p-12 text-center">
            <Users className="w-12 h-12 mx-auto text-gray-400 mb-4" />
            <h2 className="text-xl font-semibold mb-2">No Services Added</h2>
            <p className="text-gray-600 mb-6">Start tracking your managed services</p>
            <button
              onClick={() => setIsFormOpen(true)}
              className="inline-flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
              <Plus className="w-4 h-4" />
              <span>Add Your First Service</span>
            </button>
          </div>
        </div>
      ) : (
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
          {services.map((service) => (
            <div
              key={service.id}
              className="bg-white rounded-xl shadow-sm border border-gray-100 p-6 space-y-4"
            >
              <div className="flex items-start justify-between">
                <div>
                  <h3 className="font-semibold text-lg">{getServiceLabel(service.name)}</h3>
                  <p className="text-gray-600">{service.provider}</p>
                </div>
                <span className={`px-2 py-1 rounded-md text-sm ${getStatusColor(service.status)}`}>
                  {service.status.charAt(0).toUpperCase() + service.status.slice(1)}
                </span>
              </div>

              <div className="space-y-2">
                <div className="flex items-center space-x-2 text-sm text-gray-600">
                  <Calendar className="w-4 h-4" />
                  <span>{getFrequencyLabel(service.frequency)}</span>
                </div>
                <div className="text-sm">
                  <div className="flex justify-between text-gray-600">
                    <span>Last service:</span>
                    <span>{new Date(service.last_service_date).toLocaleDateString()}</span>
                  </div>
                  <div className="flex justify-between text-gray-600">
                    <span>Next service:</span>
                    <span>{new Date(service.next_service_date).toLocaleDateString()}</span>
                  </div>
                </div>
              </div>

              {service.notes && (
                <p className="text-sm text-gray-600 border-t border-gray-100 pt-3">
                  {service.notes}
                </p>
              )}
            </div>
          ))}
        </div>
      )}

      {isFormOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
          <div className="bg-white rounded-xl shadow-xl max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">Add Managed Service</h2>
              <button
                onClick={() => setIsFormOpen(false)}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Service Type
                </label>
                <select
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                >
                  {serviceTypes.map((type) => (
                    <option key={type.id} value={type.id}>
                      {type.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Service Provider
                </label>
                <input
                  type="text"
                  value={formData.provider}
                  onChange={(e) => setFormData({ ...formData, provider: e.target.value })}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Enter provider name"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Service Frequency
                </label>
                <select
                  value={formData.frequency}
                  onChange={(e) => setFormData({ ...formData, frequency: e.target.value })}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                >
                  {frequencies.map((frequency) => (
                    <option key={frequency.value} value={frequency.value}>
                      {frequency.label}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Last Service Date
                </label>
                <input
                  type="date"
                  value={formData.last_service_date}
                  onChange={(e) => setFormData({ ...formData, last_service_date: e.target.value })}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Notes
                </label>
                <textarea
                  value={formData.notes}
                  onChange={(e) => setFormData({ ...formData, notes: e.target.value })}
                  rows={3}
                  className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Optional notes about the service"
                />
              </div>

              {error && (
                <p className="text-red-500 text-sm">{error}</p>
              )}

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsFormOpen(false)}
                  className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={submitting}
                  className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors disabled:opacity-50"
                >
                  {submitting ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Adding...</span>
                    </>
                  ) : (
                    <span>Add Service</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default Services;