import React from 'react';
import { ClipboardCheck, Users, Calendar, Bell, Shield, HeartHandshake } from 'lucide-react';

const HowItWorks = () => {
  const steps = [
    {
      icon: ClipboardCheck,
      title: 'Sign Up & Assessment',
      description: "Create your account and tell us about your home. We'll analyze your property's unique needs and requirements."
    },
    {
      icon: Calendar,
      title: 'Custom Maintenance Plan',
      description: "We create a personalized maintenance schedule tailored to your home's systems, appliances, and features."
    },
    {
      icon: Users,
      title: 'Provider Management',
      description: 'We handle the coordination with trusted service providers, ensuring quality work and timely service.'
    },
    {
      icon: Bell,
      title: 'Proactive Monitoring',
      description: 'Stay ahead of maintenance with smart reminders and real-time tracking of all home-related tasks.'
    },
    {
      icon: Shield,
      title: 'Complete Documentation',
      description: "All your home's maintenance records, warranties, and important documents in one secure place."
    },
    {
      icon: HeartHandshake,
      title: 'Ongoing Support',
      description: 'Our team is here to help whenever you need assistance with your home maintenance needs.'
    }
  ];

  return (
    <div className="bg-white py-24">
      <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
        <div className="text-center max-w-3xl mx-auto mb-16">
          <h2 className="text-4xl font-bold mb-6">How HoneyDone Works</h2>
          <p className="text-xl text-gray-600">
            We make home maintenance simple and stress-free. Our comprehensive approach ensures your 
            home is always well-maintained, while you focus on what matters most.
          </p>
        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
          {steps.map((step, index) => {
            const Icon = step.icon;
            return (
              <div 
                key={index}
                className="bg-gray-50 rounded-xl p-8 hover:bg-gray-100 transition-colors"
              >
                <div className="w-12 h-12 bg-black rounded-lg flex items-center justify-center mb-6">
                  <Icon className="w-6 h-6 text-white" />
                </div>
                <h3 className="text-xl font-semibold mb-3">{step.title}</h3>
                <p className="text-gray-600">{step.description}</p>
              </div>
            );
          })}
        </div>

        <div className="mt-16 bg-black text-white rounded-2xl p-8 md:p-12">
          <div className="max-w-3xl mx-auto text-center">
            <h3 className="text-2xl md:text-3xl font-bold mb-6">
              Your Home&apos;s Maintenance Partner
            </h3>
            <p className="text-lg text-gray-300 mb-8">
              From routine maintenance to emergency repairs, we&apos;re here to ensure your home 
              is always at its best. Our team of experts handles everything while keeping 
              you informed every step of the way.
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
              <div className="bg-white/10 rounded-lg p-6">
                <h4 className="text-xl font-semibold mb-2">24/7 Support</h4>
                <p className="text-gray-300">Always available when you need assistance</p>
              </div>
              <div className="bg-white/10 rounded-lg p-6">
                <h4 className="text-xl font-semibold mb-2">Trusted Providers</h4>
                <p className="text-gray-300">Vetted and reliable service professionals</p>
              </div>
              <div className="bg-white/10 rounded-lg p-6">
                <h4 className="text-xl font-semibold mb-2">Peace of Mind</h4>
                <p className="text-gray-300">Your home is in good hands</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HowItWorks;