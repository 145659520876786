import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Users, ArrowRight, Calendar } from 'lucide-react';

interface ManagedService {
  id: string;
  name: string;
  provider: string;
  frequency: string;
  next_service_date: string;
  status: 'active' | 'paused' | 'cancelled';
}

interface ManagedServicesProps {
  services: ManagedService[];
}

const ManagedServices: React.FC<ManagedServicesProps> = ({ services }) => {
  const navigate = useNavigate();

  const getServiceLabel = (serviceId: string) => {
    const serviceTypes = {
      landscaping: 'Landscaping',
      housekeeping: 'Housekeeping',
      pest_control: 'Pest Control',
      pool_maintenance: 'Pool Maintenance',
      hvac: 'HVAC Service',
      plumbing: 'Plumbing',
      electrical: 'Electrical',
      roofing: 'Roofing',
      cleaning: 'Deep Cleaning'
    };
    return serviceTypes[serviceId as keyof typeof serviceTypes] || serviceId;
  };

  return (
    <div className="bg-white rounded-xl shadow-sm border border-gray-100">
      <div className="p-6">
        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center space-x-3">
            <Users className="w-6 h-6 text-gray-400" />
            <h2 className="text-xl font-semibold">Managed Services</h2>
          </div>
          <button 
            onClick={() => navigate('/services')}
            className="text-sm text-black hover:text-gray-600 flex items-center space-x-1"
          >
            <span>View all</span>
            <ArrowRight className="w-4 h-4" />
          </button>
        </div>

        {services.length === 0 ? (
          <div className="text-center py-6">
            <p className="text-gray-600 mb-4">No active services</p>
            <button
              onClick={() => navigate('/services')}
              className="px-4 py-2 text-sm border border-black text-black hover:bg-black hover:text-white transition-colors rounded-lg"
            >
              Add Service
            </button>
          </div>
        ) : (
          <div className="space-y-4">
            {services.map((service) => (
              <div
                key={service.id}
                className="flex items-center justify-between p-4 bg-gray-50 rounded-xl hover:bg-gray-100 transition-colors"
              >
                <div>
                  <h3 className="font-medium">{getServiceLabel(service.name)}</h3>
                  <p className="text-sm text-gray-600">{service.provider}</p>
                  <div className="flex items-center space-x-2 mt-1 text-sm text-gray-500">
                    <Calendar className="w-4 h-4" />
                    <span>Next service: {new Date(service.next_service_date).toLocaleDateString()}</span>
                  </div>
                </div>
                <button
                  onClick={() => navigate('/services')}
                  className="text-gray-400 hover:text-gray-600"
                >
                  <ArrowRight className="w-5 h-5" />
                </button>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default ManagedServices;