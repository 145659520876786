import React from 'react';
import { useNavigate } from 'react-router-dom';
import { CalendarClock, Wrench, TrendingUp, ArrowRight } from 'lucide-react';

interface Task {
  id: string;
  name: string;
  due_date: string;
  priority: string;
  status: string;
}

interface Expense {
  amount: number;
  date: string;
  category: string;
}

interface QuickStatsProps {
  tasks: Task[];
  expenses: Expense[];
}

const QuickStats: React.FC<QuickStatsProps> = ({ tasks, expenses }) => {
  const navigate = useNavigate();

  const calculateTotalSpend = () => {
    return expenses.reduce((sum, expense) => sum + expense.amount, 0);
  };

  const getSpendByCategory = () => {
    return expenses.reduce((acc, expense) => {
      acc[expense.category] = (acc[expense.category] || 0) + expense.amount;
      return acc;
    }, {} as Record<string, number>);
  };

  const getTopSpendCategory = () => {
    const spendByCategory = getSpendByCategory();
    return Object.entries(spendByCategory)
      .sort(([,a], [,b]) => b - a)[0];
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const topSpendCategory = getTopSpendCategory();

  return (
    <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
      <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Upcoming Tasks</p>
            <p className="text-2xl font-bold mt-1">{tasks.length}</p>
          </div>
          <div className="w-12 h-12 bg-gray-50 rounded-full flex items-center justify-center">
            <CalendarClock className="w-6 h-6 text-gray-600" />
          </div>
        </div>
        <p className="text-sm text-gray-500 mt-2">
          Next due: {tasks[0]?.next_due_date ? new Date(tasks[0].next_due_date).toLocaleDateString() : 'None'}
        </p>
      </div>

      <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Active Requests</p>
            <p className="text-2xl font-bold mt-1">0</p>
          </div>
          <div className="w-12 h-12 bg-gray-50 rounded-full flex items-center justify-center">
            <Wrench className="w-6 h-6 text-gray-600" />
          </div>
        </div>
        <button 
          onClick={() => navigate('/service-requests')}
          className="text-sm text-black hover:text-gray-600 mt-2 flex items-center space-x-1"
        >
          <span>Submit request</span>
          <ArrowRight className="w-4 h-4" />
        </button>
      </div>

      <div className="bg-white p-6 rounded-xl shadow-sm border border-gray-100">
        <div className="flex items-center justify-between">
          <div>
            <p className="text-sm font-medium text-gray-600">Year to Date Spend</p>
            <p className="text-2xl font-bold mt-1">{formatCurrency(calculateTotalSpend())}</p>
          </div>
          <div className="w-12 h-12 bg-gray-50 rounded-full flex items-center justify-center">
            <TrendingUp className="w-6 h-6 text-gray-600" />
          </div>
        </div>
        {topSpendCategory && (
          <p className="text-sm text-gray-500 mt-2">
            Top category: {topSpendCategory[0]} ({formatCurrency(topSpendCategory[1])})
          </p>
        )}
      </div>
    </div>
  );
};

export default QuickStats;