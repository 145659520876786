import React from 'react';
import { Link } from 'react-router-dom';
import { ArrowLeft } from 'lucide-react';

const PrivacyPolicy = () => {
  return (
    <div className="min-h-screen bg-gray-50">
      <div className="max-w-4xl mx-auto px-4 py-12">
        <Link 
          to="/" 
          className="inline-flex items-center space-x-2 text-gray-600 hover:text-gray-900 mb-8"
        >
          <ArrowLeft className="w-4 h-4" />
          <span>Back to Home</span>
        </Link>

        <div className="bg-white rounded-xl shadow-sm border border-gray-100 p-8">
          <h1 className="text-3xl font-bold mb-8">Privacy Policy</h1>
          
          <div className="space-y-6">
            <section>
              <h2 className="text-xl font-semibold mb-3">Introduction</h2>
              <p className="text-gray-600">
                At HoneyDone, we take your privacy seriously. This Privacy Policy explains how we collect, 
                use, disclose, and safeguard your information when you use our website and services.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Information We Collect</h2>
              <div className="space-y-3">
                <p className="text-gray-600">We collect information that you provide directly to us, including:</p>
                <ul className="list-disc list-inside text-gray-600 space-y-2">
                  <li>Name and contact information</li>
                  <li>Home address and property details</li>
                  <li>Service history and maintenance records</li>
                  <li>Payment information</li>
                  <li>Communication preferences</li>
                </ul>
              </div>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">How We Use Your Information</h2>
              <ul className="list-disc list-inside text-gray-600 space-y-2">
                <li>To provide and maintain our services</li>
                <li>To process your transactions</li>
                <li>To send you service notifications and updates</li>
                <li>To improve our services and user experience</li>
                <li>To communicate with you about our services</li>
              </ul>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Information Sharing</h2>
              <p className="text-gray-600">
                We do not sell or rent your personal information to third parties. We may share your 
                information with service providers who assist us in operating our website and providing 
                our services.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Data Security</h2>
              <p className="text-gray-600">
                We implement appropriate technical and organizational measures to protect your personal 
                information against unauthorized access, alteration, disclosure, or destruction.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Your Rights</h2>
              <p className="text-gray-600">
                You have the right to access, correct, or delete your personal information. You may also 
                have the right to restrict or object to certain processing of your information.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Cookies and Tracking</h2>
              <p className="text-gray-600">
                We use cookies and similar tracking technologies to track activity on our website and 
                maintain certain information to improve your experience.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Updates to This Policy</h2>
              <p className="text-gray-600">
                We may update this Privacy Policy from time to time. We will notify you of any changes by 
                posting the new Privacy Policy on this page and updating the "Last Updated" date.
              </p>
            </section>

            <section>
              <h2 className="text-xl font-semibold mb-3">Contact Us</h2>
              <p className="text-gray-600">
                If you have any questions about this Privacy Policy, please contact us at{' '}
                <a href="mailto:privacy@honeydone.com" className="text-black hover:underline">
                  privacy@honeydone.com
                </a>
              </p>
            </section>

            <div className="text-sm text-gray-500 pt-6 border-t">
              Last Updated: February 8, 2025
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PrivacyPolicy;