import React, { useState, useEffect } from 'react';
import { Plus, X, Loader2, Send, Bug, Trash2 } from 'lucide-react';
import { supabase } from '../lib/supabase';
import { useLocation } from 'react-router-dom';
import { sendServiceRequestEmail } from '../lib/email';

interface ServiceRequest {
  id: string;
  title: string;
  description: string;
  priority: 'low' | 'normal' | 'high' | 'urgent';
  status: 'pending' | 'in_progress' | 'completed' | 'cancelled';
  created_at: string;
  home_id: string;
}

interface Home {
  id: string;
  address: string;
  city: string;
  state: string;
}

const ServiceRequests = () => {
  const location = useLocation();
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [submitting, setSubmitting] = useState(false);
  const [sendingTest, setSendingTest] = useState(false);
  const [homes, setHomes] = useState<Home[]>([]);
  const [requests, setRequests] = useState<ServiceRequest[]>([]);
  const [selectedHome, setSelectedHome] = useState<string>('');
  const [error, setError] = useState('');
  const [showDebug, setShowDebug] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [formData, setFormData] = useState({
    home_id: '',
    title: '',
    description: '',
    priority: 'normal' as ServiceRequest['priority']
  });

  useEffect(() => {
    fetchHomes();
    fetchRequests();

    // Check if we should open the form
    const state = location.state as { openNewRequest?: boolean } | null;
    if (state?.openNewRequest) {
      setIsFormOpen(true);
      // Clear the state to prevent reopening on refresh
      window.history.replaceState({}, document.title);
    }

    // Add keyboard shortcut listener
    const handleKeyDown = (e: KeyboardEvent) => {
      if ((e.altKey || e.metaKey) && e.key === 'd') {
        e.preventDefault();
        setShowDebug(prev => !prev);
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, []);

  const fetchHomes = async () => {
    try {
      const { data, error } = await supabase
        .from('homes')
        .select('id, address, city, state');
      
      if (error) throw error;
      setHomes(data || []);
      
      if (data && data.length > 0) {
        setFormData(prev => ({ ...prev, home_id: data[0].id }));
      }
    } catch (error) {
      console.error('Error fetching homes:', error);
    }
  };

  const fetchRequests = async () => {
    try {
      const { data, error } = await supabase
        .from('service_requests')
        .select('*')
        .order('created_at', { ascending: false });

      if (error) throw error;
      setRequests(data || []);
    } catch (error) {
      console.error('Error fetching requests:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setSubmitting(true);
    setError('');

    try {
      const { data, error } = await supabase
        .from('service_requests')
        .insert([formData])
        .select()
        .single();

      if (error) throw error;

      const { data: notifications, error: notificationError } = await supabase
        .from('email_notifications')
        .select('*')
        .eq('service_request_id', data.id)
        .order('sent_at', { ascending: false })
        .limit(1)
        .single();

      if (notificationError) throw notificationError;

      await sendServiceRequestEmail(notifications);

      setFormData({
        home_id: homes[0]?.id || '',
        title: '',
        description: '',
        priority: 'normal'
      });
      setIsFormOpen(false);
      
      fetchRequests();

      alert('Service request submitted successfully. A confirmation email has been sent.');
    } catch (error: any) {
      setError(error.message);
      console.error('Error submitting service request:', error);
    } finally {
      setSubmitting(false);
    }
  };

  const handleTestEmail = async () => {
    setSendingTest(true);
    setError('');

    try {
      const { data: notification, error: notificationError } = await supabase
        .from('email_notifications')
        .insert([{
          recipient: 'service@hunnydone.com',
          subject: 'Test Email from HunnyDone',
          body: 'This is a test email from the HunnyDone service request system.'
        }])
        .select()
        .single();

      if (notificationError) throw notificationError;

      await sendServiceRequestEmail(notification);
      alert('Test email sent successfully to service@hunnydone.com');
    } catch (error: any) {
      setError('Failed to send test email: ' + error.message);
      console.error('Error sending test email:', error);
    } finally {
      setSendingTest(false);
    }
  };

  const handleDeleteRequest = async (id: string) => {
    if (!confirm('Are you sure you want to delete this service request?')) {
      return;
    }

    setDeleting(true);
    try {
      const { error } = await supabase
        .from('service_requests')
        .delete()
        .eq('id', id);

      if (error) throw error;
      fetchRequests();
    } catch (error: any) {
      console.error('Error deleting request:', error);
      alert('Failed to delete request: ' + error.message);
    } finally {
      setDeleting(false);
    }
  };

  const getPriorityColor = (priority: ServiceRequest['priority']) => {
    switch (priority) {
      case 'urgent': return 'text-red-600 bg-red-50';
      case 'high': return 'text-orange-600 bg-orange-50';
      case 'normal': return 'text-blue-600 bg-blue-50';
      case 'low': return 'text-green-600 bg-green-50';
      default: return 'text-gray-600 bg-gray-50';
    }
  };

  const getStatusColor = (status: ServiceRequest['status']) => {
    switch (status) {
      case 'completed': return 'text-green-600 bg-green-50';
      case 'in_progress': return 'text-blue-600 bg-blue-50';
      case 'cancelled': return 'text-gray-600 bg-gray-50';
      default: return 'text-orange-600 bg-orange-50';
    }
  };

  return (
    <div className="space-y-8">
      <header className="flex justify-between items-center">
        <div>
          <h1 className="text-3xl font-bold">Service Requests</h1>
          <p className="text-gray-600">Submit and track your service requests</p>
        </div>
        <div className="flex items-center space-x-4">
          {showDebug && (
            <div className="flex items-center space-x-2 bg-red-50 px-4 py-2 rounded-md">
              <Bug className="w-4 h-4 text-red-600" />
              <div className="flex items-center space-x-2">
                <button
                  onClick={handleTestEmail}
                  disabled={sendingTest}
                  className="flex items-center space-x-2 px-3 py-1 border border-red-600 text-red-600 hover:bg-red-600 hover:text-white rounded-md transition-colors disabled:opacity-50"
                >
                  {sendingTest ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Sending...</span>
                    </>
                  ) : (
                    <>
                      <Send className="w-4 h-4" />
                      <span>Test Email</span>
                    </>
                  )}
                </button>
                <button
                  onClick={() => requests.forEach(r => handleDeleteRequest(r.id))}
                  disabled={deleting || requests.length === 0}
                  className="flex items-center space-x-2 px-3 py-1 border border-red-600 text-red-600 hover:bg-red-600 hover:text-white rounded-md transition-colors disabled:opacity-50"
                >
                  {deleting ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Deleting...</span>
                    </>
                  ) : (
                    <>
                      <Trash2 className="w-4 h-4" />
                      <span>Delete All</span>
                    </>
                  )}
                </button>
              </div>
            </div>
          )}
          <button
            onClick={() => setIsFormOpen(true)}
            className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors"
          >
            <Plus className="w-4 h-4" />
            <span>New Request</span>
          </button>
        </div>
      </header>

      {loading ? (
        <div className="bg-white rounded-lg shadow-sm border border-gray-100 p-8">
          <div className="flex justify-center">
            <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
          </div>
        </div>
      ) : requests.length === 0 ? (
        <div className="bg-white rounded-lg shadow-sm border border-gray-100">
          <div className="p-8 text-center">
            <p className="text-gray-600">No service requests yet</p>
          </div>
        </div>
      ) : (
        <div className="bg-white rounded-lg shadow-sm border border-gray-100 divide-y divide-gray-100">
          {requests.map((request) => (
            <div key={request.id} className="p-6">
              <div className="flex justify-between items-start">
                <div className="space-y-2">
                  <h3 className="text-lg font-medium">{request.title}</h3>
                  <p className="text-gray-600">{request.description}</p>
                  <div className="flex items-center space-x-3 text-sm">
                    <span className={`px-2 py-1 rounded-md ${getPriorityColor(request.priority)}`}>
                      {request.priority.charAt(0).toUpperCase() + request.priority.slice(1)} Priority
                    </span>
                    <span className={`px-2 py-1 rounded-md ${getStatusColor(request.status)}`}>
                      {request.status.split('_').map(word => 
                        word.charAt(0).toUpperCase() + word.slice(1)
                      ).join(' ')}
                    </span>
                    {showDebug && (
                      <button
                        onClick={() => handleDeleteRequest(request.id)}
                        disabled={deleting}
                        className="flex items-center space-x-1 px-2 py-1 text-red-600 hover:bg-red-50 rounded-md transition-colors"
                      >
                        <Trash2 className="w-4 h-4" />
                        <span>Delete</span>
                      </button>
                    )}
                  </div>
                </div>
                <span className="text-sm text-gray-500">
                  {new Date(request.created_at).toLocaleDateString()}
                </span>
              </div>
            </div>
          ))}
        </div>
      )}

      {isFormOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <div className="flex justify-between items-center mb-6">
              <h2 className="text-xl font-semibold">New Service Request</h2>
              <button
                onClick={() => setIsFormOpen(false)}
                className="text-gray-400 hover:text-gray-600"
              >
                <X className="w-5 h-5" />
              </button>
            </div>

            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Home
                </label>
                <select
                  value={formData.home_id}
                  onChange={(e) => setFormData({ ...formData, home_id: e.target.value })}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                >
                  {homes.map((home) => (
                    <option key={home.id} value={home.id}>
                      {home.address}, {home.city}, {home.state}
                    </option>
                  ))}
                </select>
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Title
                </label>
                <input
                  type="text"
                  value={formData.title}
                  onChange={(e) => setFormData({ ...formData, title: e.target.value })}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Brief description of the issue"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Description
                </label>
                <textarea
                  value={formData.description}
                  onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                  required
                  rows={4}
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                  placeholder="Detailed description of the service needed"
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Priority
                </label>
                <select
                  value={formData.priority}
                  onChange={(e) => setFormData({ ...formData, priority: e.target.value as ServiceRequest['priority'] })}
                  required
                  className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-1 focus:ring-black"
                >
                  <option value="low">Low</option>
                  <option value="normal">Normal</option>
                  <option value="high">High</option>
                  <option value="urgent">Urgent</option>
                </select>
              </div>

              {error && (
                <p className="text-red-500 text-sm">{error}</p>
              )}

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={() => setIsFormOpen(false)}
                  className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  disabled={submitting}
                  className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-md hover:bg-gray-800 transition-colors disabled:opacity-50"
                >
                  {submitting ? (
                    <>
                      <Loader2 className="w-4 h-4 animate-spin" />
                      <span>Submitting...</span>
                    </>
                  ) : (
                    <span>Submit Request</span>
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      )}
    </div>
  );
};

export default ServiceRequests;