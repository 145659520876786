import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Package, ArrowRight, Calendar, Wrench } from 'lucide-react';

interface ChatActionProps {
  type: 'viewInventory' | 'viewInventoryItem' | 'viewMaintenancePlan' | 'createServiceRequest';
  data?: {
    itemId?: string;
    itemName?: string;
  };
}

const ChatAction: React.FC<ChatActionProps> = ({ type, data }) => {
  const navigate = useNavigate();

  const handleAction = () => {
    switch (type) {
      case 'viewInventory':
        navigate('/inventory');
        break;
      case 'viewInventoryItem':
        if (data?.itemId) {
          navigate('/inventory', { state: { selectedItemId: data.itemId } });
        }
        break;
      case 'viewMaintenancePlan':
        navigate('/maintenance-plan');
        break;
      case 'createServiceRequest':
        navigate('/service-requests', { state: { openNewRequest: true } });
        break;
    }
  };

  const renderContent = () => {
    switch (type) {
      case 'viewInventory':
        return (
          <>
            <Package className="w-4 h-4" />
            <span>View Inventory</span>
            <ArrowRight className="w-4 h-4" />
          </>
        );
      case 'viewInventoryItem':
        return (
          <>
            <Package className="w-4 h-4" />
            <span>View {data?.itemName || 'Item'}</span>
            <ArrowRight className="w-4 h-4" />
          </>
        );
      case 'viewMaintenancePlan':
        return (
          <>
            <Calendar className="w-4 h-4" />
            <span>View Maintenance Plan</span>
            <ArrowRight className="w-4 h-4" />
          </>
        );
      case 'createServiceRequest':
        return (
          <>
            <Wrench className="w-4 h-4" />
            <span>Create Service Request</span>
            <ArrowRight className="w-4 h-4" />
          </>
        );
      default:
        return null;
    }
  };

  return (
    <button
      onClick={handleAction}
      className="mt-2 inline-flex items-center space-x-2 px-4 py-2 bg-gray-100 text-gray-800 rounded-lg hover:bg-gray-200 transition-colors text-sm"
    >
      {renderContent()}
    </button>
  );
};

export default ChatAction;