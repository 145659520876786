import React, { useEffect, useState, useRef } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Shield, Calendar, Receipt, ArrowRight, CheckCircle2, Settings, Users, Search } from 'lucide-react';
import HowItWorks from '../components/marketing/HowItWorks';

const Marketing = () => {
  const navigate = useNavigate();
  const [currentNeed, setCurrentNeed] = useState('');
  const [isTyping, setIsTyping] = useState(true);
  const typewriterRef = useRef<NodeJS.Timeout | null>(null);
  const currentTextRef = useRef(0);

  const commonNeeds = [
    'find a landscaper',
    'install new locks',
    'maintain my pool',
    'find a housekeeper',
    'service my HVAC',
    'clean my gutters',
    'fix a leaky faucet',
    'organize home maintenance',
    'find a plumber',
    'repair my roof',
    'fix broken windows',
    'install a smart thermostat',
    'service my water heater',
    'deep clean my carpets',
    'organize my garage',
    'install a home security system',
    'schedule a termite inspection',
    'paint interior walls',
    'repair squeaky doors',
    'clean my chimney',
    'upgrade my insulation',
    'install new flooring',
    'repair my driveway',
    'find a pest control specialist',
    'organize my basement'
  ];

  const stopTypewriter = () => {
    if (typewriterRef.current) {
      clearTimeout(typewriterRef.current);
      typewriterRef.current = null;
    }
    setIsTyping(false);
  };

  const typeText = (text: string, currentIndex: number = 0) => {
    if (!isTyping) return;
    
    if (currentIndex <= text.length) {
      setCurrentNeed(text.slice(0, currentIndex));
      typewriterRef.current = setTimeout(() => {
        typeText(text, currentIndex + 1);
      }, 50); // Typing speed
    } else {
      // Wait before starting the next word
      typewriterRef.current = setTimeout(() => {
        if (isTyping) {
          currentTextRef.current = (currentTextRef.current + 1) % commonNeeds.length;
          typeText(commonNeeds[currentTextRef.current], 0);
        }
      }, 2000); // Pause between words
    }
  };

  useEffect(() => {
    // Start the typing effect
    typeText(commonNeeds[0]);

    return () => {
      if (typewriterRef.current) {
        clearTimeout(typewriterRef.current);
      }
    };
  }, []);

  useEffect(() => {
    // Update meta tags dynamically
    document.title = 'HoneyDone - Simplify Your Home Management Journey';
    
    // Update meta description
    const metaDescription = document.querySelector('meta[name="description"]');
    if (metaDescription) {
      metaDescription.setAttribute('content', 'Keep track of maintenance, manage service providers, and stay on top of your home\'s needs - all in one place. Start your free trial today!');
    }

    // Parallax effect on scroll
    const handleScroll = () => {
      const scrolled = window.scrollY;
      const hero = document.querySelector('.hero-background');
      if (hero) {
        hero.style.transform = `translateY(${scrolled * 0.5}px)`;
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    navigate('/onboarding');
  };

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      <nav className="border-b bg-white relative z-10">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="flex justify-between items-center h-16">
            <div className="flex items-center">
              <img 
                src="https://storage.googleapis.com/hdwebsite/images/hd.png" 
                alt="HoneyDone Logo" 
                className="h-8 w-auto"
              />
              <span className="ml-2 font-semibold text-xl">HoneyDone</span>
            </div>
            <div className="flex items-center space-x-4">
              <Link
                to="/login"
                className="text-gray-600 hover:text-gray-900"
              >
                Sign in
              </Link>
              <Link
                to="/onboarding"
                className="inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md text-white bg-black hover:bg-gray-800 transition-colors"
              >
                Get Started
              </Link>
            </div>
          </div>
        </div>
      </nav>

      {/* Hero Section with Parallax */}
      <div className="relative overflow-hidden h-[90vh] flex items-center">
        <div 
          className="hero-background absolute inset-0 bg-cover bg-center"
          style={{
            backgroundImage: 'url(https://storage.googleapis.com/hdwebsite/images/bgone.jpg)',
            willChange: 'transform'
          }}
        />
        <div 
          className="absolute inset-0 bg-black/40 backdrop-blur-[2px]"
          style={{ backdropFilter: 'blur(2px)' }}
        />
        
        {/* Content */}
        <div className="relative max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-24 text-white">
          <div className="text-center">
            <h1 className="text-4xl tracking-tight font-bold sm:text-5xl md:text-6xl">
              <span className="block">Simplify Managing Your Home</span>
            </h1>
            <p className="mt-3 max-w-md mx-auto text-base sm:text-lg md:mt-5 md:text-xl md:max-w-3xl text-gray-200">
              Keep track of maintenance, manage service providers, and stay on top of your home's needs - all in one place.
            </p>
            
            {/* Interactive Need Widget */}
            <div className="max-w-2xl mx-auto mt-10">
              <form onSubmit={handleSubmit} className="relative">
                <div className="flex items-center bg-white/10 backdrop-blur-md rounded-lg p-2 border border-white/20">
                  <Search className="w-6 h-6 text-white/80 ml-2" />
                  <input
                    type="text"
                    value={currentNeed}
                    readOnly
                    onFocus={() => navigate('/onboarding')}
                    className="w-full px-4 py-3 bg-transparent text-white placeholder-white/60 focus:outline-none text-lg"
                    placeholder="I need help with..."
                  />
                  <button
                    type="submit"
                    className="px-8 py-3 bg-white text-black text-xs rounded-md hover:bg-gray-100 transition-colors font-medium min-w-[120px]"
                  >
                    Help Me
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>

      {/* How It Works section */}
      <HowItWorks />

      {/* Service Tiers Section */}
      <div className="py-24 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center mb-16">
            <h2 className="text-4xl font-bold mb-4">Choose Your Path</h2>
            <p className="text-xl text-gray-600 max-w-2xl mx-auto">
              Whether you prefer to manage your home yourself or want us to handle everything, 
              we've got you covered.
            </p>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-5xl mx-auto">
            {/* Basic Tier */}
            <div className="bg-white p-8 rounded-2xl shadow-sm border border-gray-100 hover:border-gray-300 transition-colors">
              <div className="flex items-center justify-between mb-6">
                <div>
                  <h3 className="text-2xl font-bold">Basic</h3>
                  <p className="text-gray-600 mt-1">Self-Managed</p>
                </div>
                <div className="w-12 h-12 bg-gray-50 rounded-full flex items-center justify-center">
                  <Settings className="w-6 h-6 text-gray-600" />
                </div>
              </div>
              <div className="space-y-4 mb-8">
                <p className="text-gray-600">
                  Perfect for hands-on homeowners who want to stay organized and in control.
                </p>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-gray-400 mr-2" />
                    <span>Custom maintenance plan</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-gray-400 mr-2" />
                    <span>Maintenance tracking & reminders</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-gray-400 mr-2" />
                    <span>Expense tracking</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-gray-400 mr-2" />
                    <span>Available nationwide</span>
                  </li>
                </ul>
              </div>
              <Link
                to="/onboarding"
                className="inline-flex items-center justify-center w-full px-6 py-3 border-2 border-black text-black hover:bg-black hover:text-white transition-colors rounded-lg font-medium"
              >
                Get Started Free
                <ArrowRight className="ml-2 w-5 h-5" />
              </Link>
            </div>

            {/* Premium Tier */}
            <div className="bg-black text-white p-8 rounded-2xl shadow-lg relative overflow-hidden">
              <div className="absolute top-0 right-0 bg-white text-black px-4 py-1 text-sm font-medium">
                Select Markets Only
              </div>
              <div className="flex items-center justify-between mb-6">
                <div>
                  <h3 className="text-2xl font-bold">Premium</h3>
                  <p className="text-gray-400 mt-1">Professionally Managed</p>
                </div>
                <div className="w-12 h-12 bg-white/10 rounded-full flex items-center justify-center">
                  <Users className="w-6 h-6 text-white" />
                </div>
              </div>
              <div className="space-y-4 mb-8">
                <p className="text-gray-300">
                  Let our team of professionals handle all your home maintenance needs.
                </p>
                <ul className="space-y-3">
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-white mr-2" />
                    <span>All Basic features included</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-white mr-2" />
                    <span>Dedicated home manager</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-white mr-2" />
                    <span>Professional maintenance service</span>
                  </li>
                  <li className="flex items-center">
                    <CheckCircle2 className="w-5 h-5 text-white mr-2" />
                    <span>24/7 emergency support</span>
                  </li>

                </ul>
              </div>
              <Link
                to="/onboarding"
                className="inline-flex items-center justify-center w-full px-6 py-3 bg-white text-black hover:bg-gray-100 transition-colors rounded-lg font-medium"
              >
                Learn More
                <ArrowRight className="ml-2 w-5 h-5" />
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* Service Areas Section */}
      <div className="bg-black py-24">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <h2 className="text-4xl font-bold text-white text-center mb-4">
            HoneyDone Premium serves homes in<br />
            West Michigan
          </h2>
          <p className="text-gray-300 text-center mb-16 max-w-2xl mx-auto">
            HoneyDone basic is available everywhere in the United States. HoneyDone Premium is only available in select areas.
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-4xl mx-auto">
            {/* Grand Rapids Card */}
            <div className="relative group">
              <div className="relative h-80 w-full overflow-hidden rounded-lg">
                <img
                  src="https://storage.googleapis.com/hdwebsite/images/gr_home_two.jpg"
                  alt="Grand Rapids skyline"
                  className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <h3 className="absolute bottom-6 left-6 text-3xl font-semibold text-white">
                  Grand Rapids
                </h3>
              </div>
            </div>

            {/* Holland Card */}
            <div className="relative group">
              <div className="relative h-80 w-full overflow-hidden rounded-lg">
                <img
                  src="https://storage.googleapis.com/hdwebsite/images/gr_home_one.jpg"
                  alt="Holland lighthouse"
                  className="h-full w-full object-cover transition-transform duration-300 group-hover:scale-105"
                />
                <div className="absolute inset-0 bg-gradient-to-t from-black/60 to-transparent" />
                <h3 className="absolute bottom-6 left-6 text-3xl font-semibold text-white">
                  Holland
                </h3>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Features Section */}
      <div className="py-16 bg-gray-50">
        <div className="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8">
          <div className="text-center">
            <h2 className="text-3xl font-bold text-gray-900">
              Everything you need to manage your home
            </h2>
            <p className="mt-4 text-lg text-gray-600">
              Comprehensive tools designed to make home maintenance simple and efficient
            </p>
          </div>

          <div className="mt-16">
            <div className="grid grid-cols-1 gap-8 sm:grid-cols-2 lg:grid-cols-3">
              <div className="pt-6">
                <div className="flow-root bg-white rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-black rounded-md shadow-lg">
                        <Calendar className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      Maintenance Tracking
                    </h3>
                    <p className="mt-5 text-base text-gray-500">
                      Never miss important maintenance tasks with automated reminders and scheduling
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6">
                <div className="flow-root bg-white rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-black rounded-md shadow-lg">
                        <Receipt className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      Expense Management
                    </h3>
                    <p className="mt-5 text-base text-gray-500">
                      Track all your home-related expenses and generate detailed reports
                    </p>
                  </div>
                </div>
              </div>

              <div className="pt-6">
                <div className="flow-root bg-white rounded-lg px-6 pb-8">
                  <div className="-mt-6">
                    <div>
                      <span className="inline-flex items-center justify-center p-3 bg-black rounded-md shadow-lg">
                        <Shield className="h-6 w-6 text-white" />
                      </span>
                    </div>
                    <h3 className="mt-8 text-lg font-medium text-gray-900 tracking-tight">
                      Service Provider Management
                    </h3>
                    <p className="mt-5 text-base text-gray-500">
                      Manage all your service providers and maintenance professionals in one place
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* CTA Section */}
      <div className="bg-gray-50">
        <div className="max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8">
          <div className="bg-black rounded-lg shadow-xl overflow-hidden">
            <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
              <div className="lg:self-center">
                <h2 className="text-3xl font-bold text-white sm:text-4xl">
                  <span className="block">Ready to get started?</span>
                </h2>
                <p className="mt-4 text-lg leading-6 text-gray-200">
                  Join thousands of homeowners who trust HoneyDone to manage their homes.
                </p>
                <ul className="mt-8 space-y-4">
                  {['Free 30-day trial', 'No credit card required', 'Cancel anytime'].map((feature, index) => (
                    <li key={index} className="flex items-center text-gray-200">
                      <CheckCircle2 className="h-5 w-5 mr-2" />
                      {feature}
                    </li>
                  ))}
                </ul>
                <Link
                  to="/onboarding"
                  className="mt-8 inline-flex items-center px-6 py-3 border border-transparent text-base font-medium rounded-md text-black bg-white hover:bg-gray-100 transition-colors"
                >
                  Start Free Trial
                  <ArrowRight className="ml-2 w-5 h-5" />
                </Link>
              </div>
            </div>
          </div>
         </div>
      </div>

      {/* Footer */}
      <footer className="bg-white">
        <div className="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:px-8">
          <div className="flex flex-col md:flex-row justify-between items-center space-y-4 md:space-y-0">
            <div className="flex items-center">
              <img 
                src="https://storage.googleapis.com/hdwebsite/images/hd.png" 
                alt="HoneyDone Logo" 
                className="h-6 w-auto"
              />
              <span className="ml-2 text-gray-900">HoneyDone</span>
            </div>
            <div className="flex items-center space-x-6">
              <Link to="/privacy" className="text-gray-500 hover:text-gray-900">
                Privacy Policy
              </Link>
              <span className="text-gray-500 text-sm">
                © 2025 HoneyDone. All rights reserved.
              </span>
            </div>
          </div>
        </div>
      </footer>
    </div>
  );
};

export default Marketing;