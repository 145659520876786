import React, { useState, useEffect } from 'react';
import { X, Calendar, DollarSign, Wrench, Shield, Upload, Loader2, Plus, FileText, Trash2 } from 'lucide-react';
import { supabase } from '../../lib/supabase';

interface ItemDetailModalProps {
  itemId: string;
  onClose: () => void;
  onUpdate: () => void;
}

interface Warranty {
  id: string;
  provider: string;
  coverage_details: string;
  start_date: string;
  end_date: string;
  documents: string[];
}

interface MaintenanceRecord {
  id: string;
  service_date: string;
  service_type: string;
  provider: string;
  cost: number;
  description: string;
  documents: string[];
}

const serviceTypes = [
  'Repair',
  'Maintenance',
  'Inspection',
  'Cleaning',
  'Replacement',
  'Other'
];

const ItemDetailModal: React.FC<ItemDetailModalProps> = ({ itemId, onClose, onUpdate }) => {
  const [loading, setLoading] = useState(true);
  const [warranties, setWarranties] = useState<Warranty[]>([]);
  const [maintenanceRecords, setMaintenanceRecords] = useState<MaintenanceRecord[]>([]);
  const [activeTab, setActiveTab] = useState<'warranty' | 'maintenance'>('warranty');
  const [isAddingWarranty, setIsAddingWarranty] = useState(false);
  const [isAddingMaintenance, setIsAddingMaintenance] = useState(false);
  const [error, setError] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const [showDeleteConfirm, setShowDeleteConfirm] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [warrantyForm, setWarrantyForm] = useState({
    provider: '',
    coverage_details: '',
    start_date: new Date().toISOString().split('T')[0],
    end_date: new Date().toISOString().split('T')[0],
    documents: [] as string[]
  });

  const [maintenanceForm, setMaintenanceForm] = useState({
    service_date: new Date().toISOString().split('T')[0],
    service_type: serviceTypes[0],
    provider: '',
    cost: '0',
    description: '',
    documents: [] as string[]
  });

  useEffect(() => {
    fetchWarranties();
    fetchMaintenanceRecords();
  }, [itemId]);

  const fetchWarranties = async () => {
    try {
      const { data, error } = await supabase
        .from('warranties')
        .select('*')
        .eq('item_id', itemId)
        .order('end_date', { ascending: true });

      if (error) throw error;
      setWarranties(data || []);
    } catch (error) {
      console.error('Error fetching warranties:', error);
    }
  };

  const fetchMaintenanceRecords = async () => {
    try {
      const { data, error } = await supabase
        .from('maintenance_records')
        .select('*')
        .eq('item_id', itemId)
        .order('service_date', { ascending: false });

      if (error) throw error;
      setMaintenanceRecords(data || []);
    } catch (error) {
      console.error('Error fetching maintenance records:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleDocumentUpload = async (e: React.ChangeEvent<HTMLInputElement>, type: 'warranty' | 'maintenance') => {
    const files = e.target.files;
    if (!files || files.length === 0) return;

    try {
      const uploadPromises = Array.from(files).map(async (file) => {
        const fileExt = file.name.split('.').pop();
        const fileName = `${Math.random()}.${fileExt}`;
        const filePath = `documents/${fileName}`;

        const { error: uploadError } = await supabase.storage
          .from('inventory')
          .upload(filePath, file);

        if (uploadError) {
          console.error('Upload error:', uploadError);
          throw uploadError;
        }

        const { data: { publicUrl } } = supabase.storage
          .from('inventory')
          .getPublicUrl(filePath);

        return publicUrl;
      });

      const uploadedUrls = await Promise.all(uploadPromises);
      
      if (type === 'warranty') {
        setWarrantyForm(prev => ({
          ...prev,
          documents: [...prev.documents, ...uploadedUrls]
        }));
      } else {
        setMaintenanceForm(prev => ({
          ...prev,
          documents: [...prev.documents, ...uploadedUrls]
        }));
      }
    } catch (error) {
      console.error('Error uploading documents:', error);
      setError('Failed to upload documents. Please try again.');
    }
  };

  const handleWarrantySubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError('');

    try {
      const { error } = await supabase
        .from('warranties')
        .insert([{
          ...warrantyForm,
          item_id: itemId
        }]);

      if (error) throw error;

      setWarrantyForm({
        provider: '',
        coverage_details: '',
        start_date: '',
        end_date: '',
        documents: []
      });
      setIsAddingWarranty(false);
      fetchWarranties();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleMaintenanceSubmit = async (e: React.FormEvent) => {
    e.preventDefault();
    setSubmitting(true);
    setError('');

    try {
      const { error } = await supabase
        .from('maintenance_records')
        .insert([{
          ...maintenanceForm,
          item_id: itemId,
          cost: parseFloat(maintenanceForm.cost)
        }]);

      if (error) throw error;

      setMaintenanceForm({
        service_date: '',
        service_type: serviceTypes[0],
        provider: '',
        cost: '',
        description: '',
        documents: []
      });
      setIsAddingMaintenance(false);
      fetchMaintenanceRecords();
    } catch (error: any) {
      setError(error.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleDeleteWarranty = async (warrantyId: string) => {
    if (!confirm('Are you sure you want to delete this warranty?')) return;

    try {
      const { error } = await supabase
        .from('warranties')
        .delete()
        .eq('id', warrantyId);

      if (error) throw error;
      fetchWarranties();
    } catch (error) {
      console.error('Error deleting warranty:', error);
      setError('Failed to delete warranty');
    }
  };

  const handleDeleteMaintenance = async (recordId: string) => {
    if (!confirm('Are you sure you want to delete this maintenance record?')) return;

    try {
      const { error } = await supabase
        .from('maintenance_records')
        .delete()
        .eq('id', recordId);

      if (error) throw error;
      fetchMaintenanceRecords();
    } catch (error) {
      console.error('Error deleting maintenance record:', error);
      setError('Failed to delete maintenance record');
    }
  };

  const handleDelete = async () => {
    try {
      setDeleting(true);
      setError('');

      const { error } = await supabase
        .from('inventory_items')
        .delete()
        .eq('id', itemId);

      if (error) throw error;

      onUpdate();
      onClose();
    } catch (error: any) {
      console.error('Error deleting item:', error);
      setError('Failed to delete item. Please try again.');
    } finally {
      setDeleting(false);
    }
  };

  const formatCurrency = (amount: number) => {
    return new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    }).format(amount);
  };

  const renderWarranties = () => {
    if (isAddingWarranty) {
      return (
        <form onSubmit={handleWarrantySubmit} className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Provider
            </label>
            <input
              type="text"
              value={warrantyForm.provider}
              onChange={(e) => setWarrantyForm({ ...warrantyForm, provider: e.target.value })}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
              placeholder="Warranty provider name"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Coverage Details
            </label>
            <textarea
              value={warrantyForm.coverage_details}
              onChange={(e) => setWarrantyForm({ ...warrantyForm, coverage_details: e.target.value })}
              required
              rows={3}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
              placeholder="Describe warranty coverage"
            />
          </div>

          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Start Date
              </label>
              <input
                type="date"
                value={warrantyForm.start_date}
                onChange={(e) => setWarrantyForm({ ...warrantyForm, start_date: e.target.value })}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                End Date
              </label>
              <input
                type="date"
                value={warrantyForm.end_date}
                onChange={(e) => setWarrantyForm({ ...warrantyForm, end_date: e.target.value })}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Documents
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label className="relative cursor-pointer rounded-md font-medium text-black hover:text-gray-800">
                    <span>Upload files</span>
                    <input
                      type="file"
                      multiple
                      onChange={(e) => handleDocumentUpload(e, 'warranty')}
                      className="sr-only"
                    />
                  </label>
                </div>
                <p className="text-xs text-gray-500">PDF, PNG, JPG up to 10MB</p>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setIsAddingWarranty(false)}
              className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors disabled:opacity-50"
            >
              {submitting ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <span>Adding...</span>
                </>
              ) : (
                <span>Add Warranty</span>
              )}
            </button>
          </div>
        </form>
      );
    }

    return (
      <div className="space-y-4">
        {warranties.length === 0 ? (
          <div className="text-center py-8">
            <Shield className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium mb-2">No Warranties</h3>
            <p className="text-gray-600 mb-4">Add warranty information for this item</p>
            <button
              onClick={() => setIsAddingWarranty(true)}
              className="inline-flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
              <Plus className="w-4 h-4" />
              <span>Add Warranty</span>
            </button>
          </div>
        ) : (
          <>
            <div className="flex justify-end">
              <button
                onClick={() => setIsAddingWarranty(true)}
                className="inline-flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
              >
                <Plus className="w-4 h-4" />
                <span>Add Warranty</span>
              </button>
            </div>
            <div className="space-y-4">
              {warranties.map((warranty) => (
                <div
                  key={warranty.id}
                  className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors"
                >
                  <div className="flex justify-between items-start mb-2">
                    <h3 className="font-medium">{warranty.provider}</h3>
                    <button
                      onClick={() => handleDeleteWarranty(warranty.id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                  <p className="text-sm text-gray-600 mb-3">{warranty.coverage_details}</p>
                  <div className="flex items-center justify-between text-sm text-gray-600">
                    <div className="flex items-center space-x-2">
                      <Calendar className="w-4 h-4" />
                      <span>
                        {new Date(warranty.start_date).toLocaleDateString()} - {new Date(warranty.end_date).toLocaleDateString()}
                      </span>
                    </div>
                    {warranty.documents && warranty.documents.length > 0 && (
                      <div className="flex items-center space-x-2">
                        <FileText className="w-4 h-4" />
                        <span>{warranty.documents.length} document(s)</span>
                      </div>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  const renderMaintenanceRecords = () => {
    if (isAddingMaintenance) {
      return (
        <form onSubmit={handleMaintenanceSubmit} className="space-y-4">
          <div className="grid grid-cols-2 gap-4">
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Service Date
              </label>
              <input
                type="date"
                value={maintenanceForm.service_date}
                onChange={(e) => setMaintenanceForm({ ...maintenanceForm, service_date: e.target.value })}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
              />
            </div>
            <div>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Service Type
              </label>
              <select
                value={maintenanceForm.service_type}
                onChange={(e) => setMaintenanceForm({ ...maintenanceForm, service_type: e.target.value })}
                required
                className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
              >
                {serviceTypes.map((type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Service Provider
            </label>
            <input
              type="text"
              value={maintenanceForm.provider}
              onChange={(e) => setMaintenanceForm({ ...maintenanceForm, provider: e.target.value })}
              required
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
              placeholder="Service provider name"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Cost
            </label>
            <div className="relative">
              <DollarSign className="absolute left-3 top-1/2 transform -translate-y-1/2 w-4 h-4 text-gray-400" />
              <input
                type="number"
                step="0.01"
                value={maintenanceForm.cost}
                onChange={(e) => setMaintenanceForm({ ...maintenanceForm, cost: e.target.value })}
                required
                className="w-full pl-9 pr-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
                placeholder="0.00"
              />
            </div>
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Description
            </label>
            <textarea
              value={maintenanceForm.description}
              onChange={(e) => setMaintenanceForm({ ...maintenanceForm, description: e.target.value })}
              required
              rows={3}
              className="w-full px-3 py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-1 focus:ring-black"
              placeholder="Describe the service performed"
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Documents
            </label>
            <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-lg">
              <div className="space-y-1 text-center">
                <Upload className="mx-auto h-12 w-12 text-gray-400" />
                <div className="flex text-sm text-gray-600">
                  <label className="relative cursor-pointer rounded-md font-medium text-black hover:text-gray-800">
                    <span>Upload files</span>
                    <input
                      type="file"
                      multiple
                      onChange={(e) => handleDocumentUpload(e, 'maintenance')}
                      className="sr-only"
                    />
                  </label>
                </div>
                <p className="text-xs text-gray-500">PDF, PNG, JPG up to 10MB</p>
              </div>
            </div>
          </div>

          <div className="flex justify-end space-x-3">
            <button
              type="button"
              onClick={() => setIsAddingMaintenance(false)}
              className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
            >
              Cancel
            </button>
            <button
              type="submit"
              disabled={submitting}
              className="flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors disabled:opacity-50"
            >
              {submitting ? (
                <>
                  <Loader2 className="w-4 h-4 animate-spin" />
                  <span>Adding...</span>
                </>
              ) : (
                <span>Add Record</span>
              )}
            </button>
          </div>
        </form>
      );
    }

    return (
      <div className="space-y-4">
        {maintenanceRecords.length === 0 ? (
          <div className="text-center py-8">
            <Wrench className="w-12 h-12 text-gray-400 mx-auto mb-4" />
            <h3 className="text-lg font-medium mb-2">No Maintenance Records</h3>
            <p className="text-gray-600 mb-4">Track maintenance and repairs for this item</p>
            <button
              onClick={() => setIsAddingMaintenance(true)}
              className="inline-flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
            >
              <Plus className="w-4 h-4" />
              <span>Add Record</span>
            </button>
          </div>
        ) : (
          <>
            <div className="flex justify-end">
              <button
                onClick={() => setIsAddingMaintenance(true)}
                className="inline-flex items-center space-x-2 px-4 py-2 bg-black text-white rounded-lg hover:bg-gray-800 transition-colors"
              >
                <Plus className="w-4 h-4" />
                <span>Add Record</span>
              </button>
            </div>
            <div className="space-y-4">
              {maintenanceRecords.map((record) => (
                <div
                  key={record.id}
                  className="bg-gray-50 rounded-lg p-4 hover:bg-gray-100 transition-colors"
                >
                  <div className="flex justify-between items-start mb-2">
                    <div>
                      <h3 className="font-medium">{record.service_type}</h3>
                      <p className="text-sm text-gray-600">{record.provider}</p>
                    </div>
                    <button
                      onClick={() => handleDeleteMaintenance(record.id)}
                      className="text-red-600 hover:text-red-800"
                    >
                      <Trash2 className="w-4 h-4" />
                    </button>
                  </div>
                  <p className="text-sm text-gray-600 mb-3">{record.description}</p>
                  <div className="flex items-center justify-between text-sm">
                    <div className="flex items-center space-x-2 text-gray-600">
                      <Calendar className="w-4 h-4" />
                      <span>{new Date(record.service_date).toLocaleDateString()}</span>
                    </div>
                    <span className="font-medium">{formatCurrency(record.cost)}</span>
                  </div>
                  {record.documents && record.documents.length > 0 && (
                    <div className="flex items-center space-x-2 mt-2 text-sm text-gray-600">
                      <FileText className="w-4 h-4" />
                      <span>{record.documents.length} document(s)</span>
                    </div>
                  )}
                </div>
              ))}
            </div>
          </>
        )}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-50">
      <div className="bg-white rounded-xl shadow-xl max-w-2xl w-full p-6">
        <div className="flex justify-between items-center mb-6">
          <div className="flex items-center space-x-4">
            <button
              onClick={() => setActiveTab('warranty')}
              className={`px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'warranty'
                  ? 'bg-black text-white'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Warranty
            </button>
            <button
              onClick={() => setActiveTab('maintenance')}
              className={`px-4 py-2 rounded-lg transition-colors ${
                activeTab === 'maintenance'
                  ? 'bg-black text-white'
                  : 'text-gray-600 hover:bg-gray-100'
              }`}
            >
              Maintenance
            </button>
          </div>
          <div className="flex items-center space-x-2">
            <button
              onClick={() => setShowDeleteConfirm(true)}
              className="p-2 text-red-600 hover:bg-red-50 rounded-lg transition-colors"
            >
              <Trash2 className="w-5 h-5" />
            </button>
            <button
              onClick={onClose}
              className="text-gray-400 hover:text-gray-600"
            >
              <X className="w-5 h-5" />
            </button>
          </div>
        </div>

        {loading ? (
          <div className="flex justify-center py-12">
            <Loader2 className="w-8 h-8 animate-spin text-gray-400" />
          </div>
        ) : (
          <div className="space-y-6">
            {error && (
              <p className="text-red-500 text-sm">{error}</p>
            )}

            {activeTab === 'warranty' ? renderWarranties() : renderMaintenanceRecords()}
          </div>
        )}
      </div>

      {showDeleteConfirm && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4 z-[60]">
          <div className="bg-white rounded-lg shadow-xl max-w-md w-full p-6">
            <h2 className="text-xl font-semibold mb-4">Delete Item</h2>
            <p className="text-gray-600 mb-6">
              Are you sure you want to delete this item? This action cannot be undone.
            </p>
            <div className="flex justify-end space-x-3">
              <button
                onClick={() => setShowDeleteConfirm(false)}
                className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
              >
                Cancel
              </button>
              <button
                onClick={handleDelete}
                disabled={deleting}
                className="flex items-center space-x-2 px-4 py-2 bg-red-600 text-white rounded-lg hover:bg-red-700 transition-colors disabled:opacity-50"
              >
                {deleting ? (
                  <>
                    <Loader2 className="w-4 h-4 animate-spin" />
                    <span>Deleting...</span>
                  </>
                ) : (
                  <>
                    <Trash2 className="w-4 h-4" />
                    <span>Delete</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ItemDetailModal;